<template>
  <div class="btnWrapper" ref="btnWrapper">
      <div class="accountBtn btn" v-if="loggedIn" @click="togglePopup('accountPopup')">
        <p class="accountBtnZh" v-if="language === 'zh'">賬戶</p>
        <p class="accountBtnEn" v-if="language === 'en'">ACCOUNT</p>
      </div>
      <div class="loginBtn btn" v-else @click="togglePopup('loginPopup')">
        <p v-if="language === 'zh'">登入</p>
        <p v-if="language === 'en'">LOGIN</p>
      </div>
      <div class="aboutBtn btn" @click="togglePopup('aboutPopup')">
        <p v-if="language === 'zh'">關於</p>
        <p v-if="language === 'en'">ABOUT</p>
      </div>
      <div class="aboutBtn btn" @click="togglePopup('coursePopup')">
        <p v-if="language === 'zh'">課程</p>
        <p v-if="language === 'en'">COURSE</p>
      </div>
      <div class="aboutBtn btn" @click="togglePopup('certListPopup')">
        <p v-if="language === 'zh'">合格技師</p>
        <p v-if="language === 'en'">CERTIFIED ARTISTS</p>
      </div>
      <div class="faqBtn btn" @click="togglePopup('faqPopup')">
        <p v-if="language === 'zh'">常見問題</p>
        <p v-if="language === 'en'">FAQ</p>
      </div>
      <div class="contactBtn btn" @click="togglePopup('contactPopup')">
        <p v-if="language === 'zh'">聯絡</p>
        <p v-if="language === 'en'">CONTACT US</p>
      </div>
      <div class="langBtn btn" @click="languageChange">
        <p v-if="language === 'zh'">EN</p>
        <p v-if="language === 'en'">中文</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'MenuItems',
  computed: {
    menuPopup() {
      return this.$store.getters.menuPopup;
    },
    aboutPopup() {
      return this.$store.getters.aboutPopup;
    },
    loginPopup() {
      return this.$store.getters.loginPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    accountPopup() {
      return this.$store.getters.accountPopup;
    },
    contactPopup() {
      return this.$store.getters.contactPopup;
    },
    paymentPopup() {
      return this.$store.getters.paymentPopup;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    togglePopup(popup) {
      this.$store.commit("togglePopup", {popup: popup, status: true})
    },
    goHome() {
      this.$store.commit("closeAllPopup");
    },
    coursePopup() {
    if(this.loggedIn) {
        this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
        this.$store.commit('accountPage', "lecture");
      }
      else {
        this.$store.commit("togglePopup", {popup: "termsAndConditionsPopup", status: true})
      }
    },
    paymentPopupToggle() {
      if (!this.paymentPopup) {
        this.$store.commit("paymentPopup", true);
      }
    },
    languageChange() {
      if (this.language === "en") {
        this.$store.commit("changeLanguage", "zh");
      }
      else {
        this.$store.commit("changeLanguage", "en");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btnWrapper {
  grid-column: 3/4;
  display: flex;
  color: white;
  gap: 1em;

  @media only screen and (min-width: 768px) {
    gap: 2em;
  }

  @media only screen and (min-width: 1024px) {
    gap: 3em;
  }

  p {
    font-size: 12pt;
    font-weight: 700;

    @media only screen and (min-width: 768px) {
      font-size: 10pt;
      font-weight: 100;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 13pt;
    }
  }

  .btn {
    // grid-row: 1/2;
    align-self: center;
    justify-self: center;
    cursor: pointer;
  }
  .accountBtnEn {
    /* margin: 0 1em 0 0; */
    justify-self: start;
  }
  .accountBtnZh {
    justify-self: center;
  }
}
</style>