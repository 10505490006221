import { db } from '../firebase.js'

export class DatabaseFunctions {
  async getDataByUser(email) {
    const userDb = await db.collection("user_data").doc(email).get();
    const userData = userDb.data();
    return userData;
  }

  async getAllCertRef() {
    const colleaction = db.collection("user_data");
    const snapshot = await colleaction.get();
    const allCertRef = [];
    snapshot.forEach(doc => {
      const userData = doc.data();
      if(userData.certificateRef) {
        const certData = {
          'artisName': userData.artisName,
          'englishName': userData.englishName,
          'certificateRef': userData.certificateRef,
          'shopCountry': userData.shopCountry,
          'portfolio': userData.portfolio.replace(/^https?:\/\//, '')
        }
        allCertRef.push(certData)
      }
    });
    return allCertRef;
  }
  
  async getCertRefByYear(year) {
    const colleaction = db.collection("user_data");
    const snapshot = await colleaction.get();
    const certRefByYear = [];
    snapshot.forEach(doc => {
      const userData = doc.data();
      if(userData.examCompleteDate !== null && userData.examCompleteDate) {
        const examCompleteYear = new Date(userData.examCompleteDate*1000).getFullYear();
        if(examCompleteYear === year) {
          certRefByYear.push(userData.certificateRef)
        }
      }
    });
    return certRefByYear;
  }

  async checkCertRef(ref) {
    const colleaction = db.collection("user_data");
    const snapshot = await colleaction.get();
    let check;
    snapshot.forEach(doc => {
      const userData = doc.data();
      if(userData.certificateRef) {
        console.log(userData.certificateRef);
        if(userData.certificateRef === ref) {
          check = false;
        } else {
          check = true;
        }
      }
    });
    return check;
  }

  async checkPromoCode(code) {
    const promoCodeDb = await db.collection("promo").doc("code").get();
    const allPromoCodes = promoCodeDb.data();
    let codeValue;
    if(code in allPromoCodes) {
      codeValue = allPromoCodes[code];
      return codeValue;
    } else {
      codeValue = false;
      return codeValue;
    }
  }
}
const dbFunctions = new DatabaseFunctions();
export default dbFunctions;