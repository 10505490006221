<template>
  <div class="lectureAccountWrapper">
      <p class="lectureAccountTitle" v-if="language === 'zh'">課程</p>
      <p class="lectureAccountTitle" v-if="language === 'en'">Course</p>
    <div class="lectureAccountStatusWrapper">
      <p class="lectureAccountStatus">{{ userData.lectureProgress + "%" }}</p>
    </div>
    <div class="lectureAccountBtn" @click="purchaseFormPopup('course1')">
      <p v-if="language === 'zh'">開始</p>
      <p v-if="language === 'en'">START</p>
    </div>
  </div>
</template>

<script>
import { db } from '@/components/firebase.js';
export default {
  name: 'LectureAccount',
  data() {
    return {
      
    }
  },
  computed: {
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    launchLectures() {
      this.$router.push('/lectures');
    },
    async purchaseFormPopup(course) {
      const paymentStatus = db.collection("user_data").doc(this.userEmail);
      const data = await paymentStatus.get();
      if(data.data().payment) {
        if(course === "course1") {
          if(data.data().course1Purchase) {
            this.$router.push('/lectures');
          } else {
            this.$store.dispatch("makePayment", course)
          }
        }
        if(course === "course2") {
          if(data.data().course2Purchase) {
            this.$router.push('/lectures');
          } else {
            this.$store.dispatch("makePayment", course)
          }
        }
        if(course === "course3") {
          if(data.data().course3Purchase) {
            this.$router.push('/lectures');
          } else {
            this.$store.dispatch("makePayment", course)
          }
        }
        if(course === "course4") {
          if(data.data().course4Purchase) {
            this.$router.push('/lectures');
          } else {
            this.$store.dispatch("makePayment", course)
          }
        }
      } else {
        this.$store.commit("updatePaymentFor", course)
        this.$store.commit("togglePopup", {popup: "coursePurchaseForm", status: true})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.lectureAccountWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto auto;
}
.lectureAccountTitle {
  grid-column: 1/2;
  grid-row: 2/3;
  font-size: 24pt;
  margin: 0;
  align-self: center;
  justify-self: center;
  color: white;

  @media only screen and (min-width: 768px) {
    font-size: 30pt;
  }
}

.lectureAccountStatusWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  background-color: #D3D3D3;
  width: 12em;
  height: 10em;
  border-radius: 2em;
  justify-self: center;
  align-self: center;
  display: grid;

  @media only screen and (max-height: 654px) and (orientation: landscape) {
    height: 7.5em;
  }

  @media only screen and (min-width: 1024px) {
    width: 230px;
    height: 185px;
  }
}
.lectureAccountStatus {
  font-size: 34pt;
  font-weight: 500;
  margin: 0;
  align-self: center;
  justify-self: center;

  @media only screen and (min-width: 768px) {
    font-size: 36pt;
  }
}
.lectureAccountBtn {
  grid-column: 1/2;
  grid-row: 4/5;
  width: 70%;
  height: 50px;
  border-radius: 1.2em;
  align-self: center;
  justify-self: center;
  background-color: #3BC146;
  display: grid;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    max-width: 558px;
    max-height: 60px;
  }
}
.lectureAccountBtn:hover {
  background-color: black;
}
.lectureAccountBtn p {
  font-size: 14pt;
  color: white;
  align-self: center;
  justify-self: center;
}
// @media only screen and (max-height: 900px)  and (orientation: landscape) {
//   .lectureAccountTitle {
//     font-size: 20pt;
//   }
//   .lectureAccountText {
//     width: 70%;
//     font-size: 11pt;
//   }
//   .lectureAccountStatusWrapper {
//     width: 170px;
//     height: 136px;
//     border-radius: 2em;
//   }
//   .lectureAccountStatus {
//     font-size: 30pt;
//     font-weight: 500;
//   }
//   .lectureAccountBtn {
//     width: 400px;
//     height: 50px;
//     border-radius: 1.2em;
//   }
//   .lectureAccountBtn p {
//     font-size: 12pt;
//   }
// }
</style>
