<template>
<div class="cardPaymentFormWrapper">
  <form class="paymentForm" id="payment-form" ref="paymentForm">
    <div id="card-element" ref="cardElement"><!--Stripe.js injects the Card Element--></div>
    <button id="submit" ref="submit">
      <div class="spinner hidden" id="spinner" ref="spinner"></div>
      <span id="button-text" ref="buttonText">PAY</span>
    </button>
    <p id="card-error" ref="cardError" role="alert"></p>
    <p class="result-message hidden">
      Payment succeeded, see the result in your
      <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.
    </p>
  </form>
</div>
</template>

<script>
import { db } from '@/components/firebase.js';
let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
export default {
  name: 'CardPayment',
  props: {
    approvedCode: String
  },
  data() {
    return {
      purchase: {
        items: "lecture-exam",
        promoCode: ''
      },
      promoWarning: false
    }
  },
  computed: {
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    paymentFor() {
      return this.$store.getters.paymentFor;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    cardPaymentBackBtn() {
      this.$store.commit("changePaymentPopup", "paymentMethods");
    },
    fetchStripe() {
      this.purchase.promoCode = this.approvedCode;
      // document.querySelector("button").disabled = true;
      this.$refs.submit.disabled = true;
      fetch("https://service.asiabbp.com/create-payment-intent/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.purchase)
      })
      .then(result => {
        return result.json();
      })
      .then(data => {
        var elements = stripe.elements();
        var style = {
          base: {
            color: "#000000",
            // fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#32325d"
            }
          },
          invalid: {
            // fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        };
        var card = elements.create("card", { style: style });
        // Stripe injects an iframe into the DOM
        let cardElement = this.$refs.cardElement;
        let cardError = this.$refs.cardError;
        card.mount(cardElement);
        card.on("change", event => {
          // Disable the Pay button if there are no card details in the Element
          this.$refs.submit.disabled = event.empty;
          cardError.textContent = event.error ? event.error.message : "";
        });
        var form = this.$refs.paymentForm;
        form.addEventListener("submit", event => {
          event.preventDefault();
          // Complete payment when the submit button is clicked
          this.payWithCard(stripe, card, data.clientSecret);
        });
      });
    },
    payWithCard(stripe, card, clientSecret) {
      this.loading(true);
      stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: card
        }
      })
      .then(result => {
        if (result.error) {
          // Show error to your customer
          // this.showError(result.error.message);
          this.$store.commit("changePaymentPopup", "paymentStatus");
          this.$store.commit("changePaymentStatus", "fail");
        } else {
          // The payment succeeded!
          // this.orderComplete(result.paymentIntent.id);
          this.updateAccount();
          this.$store.commit("changePaymentPopup", "paymentStatus");
          this.$store.commit("changePaymentStatus", "success");
        }
      });
    },
    orderComplete(paymentIntentId) {
      this.loading(false);
      document
      .querySelector(".result-message a")
      .setAttribute(
        "href",
        "https://dashboard.stripe.com/test/payments/" + paymentIntentId
      );
      document.querySelector(".result-message").classList.remove("hidden");
      this.$refs.submit.disabled = true;
    },
    showError(errorMsgText) {
      // let cardError = this.$refs.cardError;
      this.loading(false);
      var errorMsg = this.$refs.cardError;
      errorMsg.textContent = errorMsgText;
      setTimeout(function() {
        errorMsg.textContent = "";
      }, 4000);
    },
    loading(isLoading) {
      let spinner = this.$refs.spinner;
      let buttonText = this.$refs.buttonText;
      if (isLoading) {
        // Disable the button and show a spinner
        
        this.$refs.submit.disabled = true;
        spinner.classList.remove("hidden");
        buttonText.classList.add("hidden");
      } else {
        this.$refs.submit.disabled = false;
        spinner.classList.add("hidden");
        buttonText.classList.remove("hidden");
      }
    },
    updateAccount() {
      let userData = db.collection("user_data").doc(this.userEmail)
      let paymentFor = this.paymentFor + "Purchase";
      return userData
      .update({
        "payment": true,
        [paymentFor]: true
      })
      .then(() => {
        console.log("Account data updated");
      })
      .catch((err) => {
        console.log("Account data updated error: " + err);
      })
    },
    // async applyPromo() {
    //   const discount = await dbFunctions.checkPromoCode(this.purchase.promoCode);
    //   if(discount) {
    //     console.log(discount);
    //     this.promoWarning = false;
    //     this.$emit('updatePrice', discount)
    //   } else {
    //     this.promoWarning = true;
    //   }
    // }
  },
  mounted() {
    setTimeout(this.fetchStripe(), 1000)
  }
}
</script>

<style lang="scss" scoped>
.cardPaymentFormWrapper {
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
  font-size: 16px;
  /* -webkit-font-smoothing: antialiased; */
  display: grid;
  /* justify-content: center;
  align-content: center; */
  grid-template-columns: 100%;
  // grid-template-rows: max-content max-content;
  // grid-template-rows: 100%;
}

.inputWrapper {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: start;

  .inputTitle {
    margin: 0;
  }

  input {
    border-radius: 6px 6px 0 0;
    margin: 0;
  }

  .warningTextWrapper {
    height: 1em;
    display: grid;

    .warningText {
      font-size: 8pt;
      color: red;
      text-align: left;
      margin: 0 0 0 1em;
      align-self: center;
    }
    .warningText2 {
      font-size: 6pt;
      color: white;
      text-align: left;
      margin: 0 0 0 1em;
      align-self: center;

      @media only screen and (min-width: 768px) {
        font-size: 8pt;
      }
    }
  }
}
.cardPaymentTitle {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 100%;
  display: grid;
  border-bottom: 1pt solid;
}
.cardPaymentTitle p {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: start;
  align-self: center;
  font-size: 21pt;
  font-weight: bold;
  margin: 0 0 0 60px;
  z-index: 0;
}
.cardIcon {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 40px;
  justify-self: center;
  align-self: center;
  /* margin: 0 15% 0 0; */
}
.cardPaymentBackBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  height: 20px;
  margin: 6% 6% 0 0;
  cursor: pointer;
  z-index: 20;
}
.paymentForm {
  grid-column: 1/2;
  /* grid-row: 3/4; */
  // grid-row: 2/3;
  width: 100%;
  margin: 2em 0;
  /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */
  border-radius: 7px;
  /* padding: 40px; */
  justify-self: center;
  align-self: center;

  @media only screen and (min-width: 768px) {
    height: 25px;
    min-width: 500px;
  }
}
/* Variables */
* {
  box-sizing: border-box;
}

/* body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
} */

/* form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
} */

input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px 4px 0 0 ;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

#payment-request-button {
  margin-bottom: 32px;
}

.cardPaymentBtnWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
}
/* Buttons and links */
button, .applyBtn {
  background: #0A2540;
  color: #ffffff;
  /* font-family: Arial, sans-serif; */
  text-align: center;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;

  &:hover {
    filter: contrast(115%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
// button:hover {
//   filter: contrast(115%);
// }
// button:disabled {
//   opacity: 0.5;
//   cursor: default;
// }

/* #submit {
  grid-column: 1/2;
  grid-row: 1/2;
}
.cardPaymentCancelBtn {
  grid-column: 2/3;
  grid-row: 1/2;
} */

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}
</style>
