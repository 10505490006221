import Home from './components/Home.vue';
import LectureWrapper from './components/lectures/LectureWrapper.vue';
import ExamWrapper from './components/exam/ExamWrapper.vue';
import CertificateDownload from './components/CertificateDownload.vue';
// import PaymentForm from './components/PaymentForm.vue';
import firebase from "firebase";
// import { router } from './main';
import VueRouter from 'vue-router'
import store from './store';

// export default [
//   {
//     path: '/',
//     name: 'home',
//     component: Home
//   },
//   {
//     path: '/lectures',
//     name: 'lectures',
//     component: LectureWrapper
//     meta: {
//       requireGuest: true
//     }
//   }
// ]

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/lectures',
    name: 'lectures',
    component: LectureWrapper,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/exam',
    name: 'exam',
    component: ExamWrapper,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: CertificateDownload,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(!firebase.auth().currentUser){
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    next()
    store.commit('updateFirstLoad', false)
  }
});

export default router;