<template>
  <div class="examAccountWrapper">
      <p class="examAccountTitle" v-if="language === 'zh'">考試</p>
      <p class="examAccountTitle" v-if="language === 'en'">Exam</p>
    <div class="examAccountStatusWrapper">
      <div class="examAccountStatus examStatus">
        <p class="examAccountStatusText" v-if="language === 'zh'">進展中</p>
        <p class="examAccountStatusText" v-if="language === 'en'">In Progress</p>
      </div>
      <div class="examAccountResult examStatus">
        <p class="examAccountResultText"><span class="examAccountResultNumber" ref="examAccountResultNumber">{{ userData.examResult }}</span> | 20</p>
      </div>
    </div>
    <div class="examAccountBtn" @click="launchExam">
      <p>START</p>
    </div>
  </div>
</template>

<script>
// import { db } from '@/components/firebase.js';
export default {
  name: 'ExamAccount',
  data() {
    return {
      
    }
  },
  computed: {
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    checkExamResult() {
      let userData = this.userData.examResult;
      let examResultNumber = this.$refs.examAccountResultNumber;
      if(userData < 18) {
        examResultNumber.style.color = "#DB1F1F";
      }
    },
    launchExam() {
      this.$router.push('/exam');
      this.$store.commit('examAnswered', false);
    }
  },
  watch: {
    userData() {
      if(this.userData.examResult < 18) {
        this.$refs.examAccountResultNumber.style.color = "#DB1F1F";
      }
    }
  },
  mounted() {
    this.checkExamResult();
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.examAccountWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 2em auto auto auto 2em;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto auto;
    gap: 0;
  }
}
.examAccountTitle {
  grid-column: 1/2;
  grid-row: 2/3;
  font-size: 24pt;
  margin: 0;
  align-self: center;
  justify-self: center;
  color: white;

  @media only screen and (min-width: 768px) {
    font-size: 30pt;
  }
}
.examAccountText {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 70%;
  font-size: 16pt;
  text-align: left;
  align-self: start;
  justify-self: center;
  margin: 0;
  color: white;
}
.examAccountStatusWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  width: 100%;
  justify-self: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  gap: 1em;

  @media only screen and (max-height: 654px) and (orientation: landscape) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
  }

  .examStatus {
    width: 4em;
    height: 3em;
    background-color: #D3D3D3;
    border-radius: 0.8em;
    align-self: center;
    font-size: 36pt;
    font-weight: 500;
    display: grid;

    @media only screen and (max-height: 654px) and (orientation: landscape) {
      width: 3.8em;
      height: 2.2em;
    }

    @media only screen and (min-width: 1024px) {
      width: 230px;
      height: 185px;
    }
  }

  .examAccountStatus {
    grid-column: 1/2;
    grid-row: 1/2;
    margin: 0;
    justify-self: center;

    @media only screen and (max-height: 654px) and (orientation: landscape) {
      margin: 0 7% 0 0;
      justify-self: end;
    }

    @media only screen and (min-width: 768px) {
      margin: 0 7% 0 0;
      justify-self: end;
    }

    .examAccountStatusText {
      font-size: 20pt;
      font-weight: 500;
      margin: 0;
      align-self: center;
      justify-self: center;

      @media only screen and (max-height: 654px) and (orientation: landscape) {
        font-size: 18pt;
      }
    }
  }
  .examAccountResult {
    grid-column: 1/2;
    grid-row: 2/3;
    margin: 0;
    justify-self: center;

    @media only screen and (max-height: 654px) and (orientation: landscape) {
      grid-column: 2/3;
      grid-row: 1/2;
      margin: 0 0 0 7%;
      justify-self: start;
    }

    @media only screen and (min-width: 768px ) {
      grid-column: 2/3;
      grid-row: 1/2;
      margin: 0 0 0 7%;
      justify-self: start;
    }

    .examAccountResultText {
      font-size: 36pt;
      font-weight: 500;
      margin: 0;
      align-self: center;
      justify-self: center;

      @media only screen and (max-height: 654px) and (orientation: landscape) {
        font-size: 30pt;
      }
    }
  }
}

.examAccountBtn {
  grid-column: 1/2;
  grid-row: 4/5;
  width: 70%;
  height: 50px;
  border-radius: 1.2em;
  align-self: center;
  justify-self: center;
  background-color: #3BC146;
  display: grid;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    max-width: 558px;
    max-height: 60px;
  }
}
.examAccountBtn:hover {
  background-color: black;
}
.examAccountBtn p {
  font-size: 14pt;
  color: white;
  align-self: center;
  justify-self: center;
}
// @media only screen and (max-height: 900px)  and (orientation: landscape) {
//   .examAccountTitle {
//     font-size: 24pt;
//   }
//   .examAccountText {
//     width: 70%;
//     font-size: 11pt;
//   }
//   .examAccountStatus {
//     width: 170px;
//     height: 136px;
//     border-radius: 0.7em;
//   }
//   .examAccountStatusText {
//     font-size: 17pt;
//   }
//   .examAccountResult {
//     width: 170px;
//     height: 136px;
//     border-radius: 0.7em;
//   }
//   .examAccountResultText {
//     font-size: 28pt;
//   }
//   .examAccountBtn {
//     width: 400px;
//     height: 50px;
//     border-radius: 1.2em;
//   }
//   .examAccountBtn p {
//     font-size: 12pt;
//   }
// }
</style>
