<template>
  <div class="popupWrapper">
    <div class="popupContent">
      <p class="popupTitle" v-if="language === 'zh'">認證紋身師</p>
      <p class="popupTitle" v-if="language === 'en'">Certified Artists</p>
      <img src="@/assets/close_btn_white.svg" class="closeBtn" @click="closePopup">
      <div class="popupTextWrapper">
        <div class="popupTableWrapper">
          <table class="certTable">
            <tr class="certTable__header">
              <td v-if="language === 'zh'">紋身師</td>
              <td v-if="language === 'zh'">證書號碼</td>
              <td v-if="language === 'zh'">作品集</td>
              <td v-if="language === 'en'">Artist</td>
              <td v-if="language === 'en'">Certificate Number</td>
              <td v-if="language === 'en'">Portfolio</td>
            </tr>
            <tr v-if="artists === null">
              <td colspan="3">
                <Loading class="loading" />
              </td>
            </tr>
            <tr class="certTable__row" v-for="(artist, index) in artists" :key="index" v-else>
              <td>{{ artist.artisName }}</td>
              <td>{{ artist.certificateRef }}</td>
              <td><a :href="'https://' + artist.portfolio" target="_blank" rel="noopener noreferrer">{{ artist.portfolio }}</a></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="aboutButtonWrapper">
        <div class="aboutSignUpBtn" @click="closePopup">
          <p class="aboutBtnText" v-if="language === 'zh'">關閉</p>
          <p class="aboutBtnText" v-if="language === 'en'">CLOSE</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dbFunctions from '@/components/functions/database.js'
import Loading from './Loading.vue';
export default {
  name: "CertifiedList",
  components: {
    Loading
  },
  data() {
    return {
      artists: null
    };
  },
  computed: {
    aboutPopup() {
      return this.$store.getters.aboutPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    closePopup() {
      this.$store.commit("togglePopup", { popup: "certListPopup", status: false });
    },
    signUpPopupToggle() {
      if (!this.signUpFormPopup) {
        if (!this.signUpFormPopup) {
          if (this.loggedIn) {
            this.$store.commit("togglePopup", { popup: "accountPopup", status: true });
          }
          else {
            this.$store.commit("togglePopup", { popup: "termsAndConditionsPopup", status: true });
          }
        }
      }
    },
    convetHyperlink (link) {
      return link.replace(/^https?:\/\//, '')
    },
    async getCertData() {
      const certData = await dbFunctions.getAllCertRef();
      certData.sort((a, b) => (b?.certificateRef??'').localeCompare(a?.certificateRef??''))
      this.artists = certData;
    }
  },
  mounted() {
    this.getCertData();
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/popupContent/popup-content.scss';
.loading {
  margin: 0 auto;
}
p {
  color: white;
}
::-webkit-scrollbar {
    display: none;
}
.aboutScrollWrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.aboutWrapper {
  grid-column: 1/2;
  /* grid-row: 2/4; */
  width: 100%;
  height: 100%;
  z-index: 20;
  justify-self: center;
  align-self: center;
  display: grid;
  /* overflow: auto; */
  margin: 2em 0 9em 0;
}
.aboutContent {
  width: 70%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 8% 84% 8%;
  /* grid-template-rows: 15% minmax(min-content, max-content) 10%; */
  background-color: #202125;
  border-radius: 1.5em;
  justify-self: center;
  align-self: center;
  overflow: auto;
  opacity: 0.8;
}
.aboutTitle {
  grid-column: 2/3;
  grid-row: 1/2;
  font-size: 26pt;
  font-weight: bold;
  text-align: left;
  align-self: center;
  margin: 7% 0 5% 0;
}
.closeBtn {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: end;
  align-self: start;
  height: 25px;
  margin-top: 6.5%;
  cursor: pointer;
}
.aboutTextWrapper {
  grid-column: 2/3;
  grid-row: 2/3;
  overflow: auto;
  align-self: start;
  /* margin-bottom: 5%; */
  height: 100%;
  background-color: white;

    // p {
    //   text-align: left;
    //   font-size: 18pt;
    //   color: #202125;
    // }

  .certTable {
    width: calc(100% - 4em);
    margin: 2em;
    border-collapse: collapse;

    &__header {
      // border: 1px solid black;
      
      // border-bottom: 1pt solid #ff000d;

      td {
        font-size: 18pt;
        color: #202125;
        font-weight: 600;
        border-bottom: 1pt solid #202125;
      }
    }

    &__row {
      td {
        font-size: 16pt;
        color: #202125;
        font-weight: 400;

        a {
          color: #202125;
        }
      }
      
    }
  }
}
.aboutButtonWrapper {
  grid-column: 2/3;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  padding: 4% 0 5% 0;
}
.aboutSignUpBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  height: 46px;
  width: 232px;
  background-color: #F66F51;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  opacity: 1;
}
.aboutSignUpBtn:hover {
  background-color: black;
}
.aboutCancelBtn {
  grid-column: 2/3;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  height: 46px;
  width: 232px;
  background-color: #F66F51;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  opacity: 1;
}
.aboutCancelBtn:hover {
  background-color: black;
}
.aboutBtnText {
  color: white;
  align-self: center;
  font-size: 12pt;
  margin: 0;
  align-self: center;
  justify-self: center;
}
@media only screen and (max-height: 900px)  and (orientation: landscape) {
  .aboutTitle {
    font-size: 22pt;
  }
  .aboutTextWrapper p {
    font-size: 14pt;
  }
  .aboutBtnText {
    font-size: 10pt;
  }
}
</style>
