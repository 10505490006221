<template>
  <div class="coursePurchase">
    <div class="coursePurchase__courseWrapper" @click="purchaseFormPopup('course1')">
      <img class="courseImg" src="@/assets/course-1.png" />
      <p class="courseTitle" v-if="language === 'zh'">課程 1</p>
      <p class="courseTitle" v-if="language === 'en'">Course 1</p>
      <p class="courseDesc">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum iure corrupti fugiat, ullam deserunt rem eaque aliquam ratione? Sequi doloribus omnis quod similique vel non repellat alias at mollitia. Iste?
      </p>
    </div>
    <div class="coursePurchase__courseWrapper" @click="purchaseFormPopup('course2')">
      <img class="courseImg" src="@/assets/course-2.png" />
      <p class="courseTitle" v-if="language === 'zh'">課程 2</p>
      <p class="courseTitle" v-if="language === 'en'">Course 2</p>
      <p class="courseDesc">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum iure corrupti fugiat, ullam deserunt rem eaque aliquam ratione? Sequi doloribus omnis quod similique vel non repellat alias at mollitia. Iste?
      </p>
    </div>
    <div class="coursePurchase__courseWrapper" @click="purchaseFormPopup('course3')">
      <img class="courseImg" src="@/assets/course-3.png" />
      <p class="courseTitle" v-if="language === 'zh'">課程 3</p>
      <p class="courseTitle" v-if="language === 'en'">Course 3</p>
      <p class="courseDesc">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum iure corrupti fugiat, ullam deserunt rem eaque aliquam ratione? Sequi doloribus omnis quod similique vel non repellat alias at mollitia. Iste?
      </p>
    </div>
    <div class="coursePurchase__courseWrapper" @click="purchaseFormPopup('course4')">
      <img class="courseImg" src="@/assets/course-4.png" />
      <p class="courseTitle" v-if="language === 'zh'">課程 4</p>
      <p class="courseTitle" v-if="language === 'en'">Course 4</p>
      <p class="courseDesc">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum iure corrupti fugiat, ullam deserunt rem eaque aliquam ratione? Sequi doloribus omnis quod similique vel non repellat alias at mollitia. Iste?
      </p>
    </div>
  </div>
</template>

<script>
import { db } from '@/components/firebase.js';
export default {
  name: 'CoursePurchase',
  data() {
    return {
      
    }
  },
  computed: {
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    downloadCert() {
      this.$router.push('/certificate');
    },
    makePayment() {
      this.$store.commit("togglePopup", {popup: "paymentPopup", status: true})
    },
    async purchaseFormPopup(course) {
      const paymentStatus = db.collection("user_data").doc(this.userEmail);
      const data = await paymentStatus.get();
      if(data.data().payment) {
        if(course === "course1") {
          if(data.data().course1Purchase) {
            this.$router.push('/lectures');
          } else {
            this.$store.dispatch("makePayment", course)
          }
        }
        if(course === "course2") {
          if(data.data().course2Purchase) {
            this.$router.push('/lectures');
          } else {
            this.$store.dispatch("makePayment", course)
          }
        }
        if(course === "course3") {
          if(data.data().course3Purchase) {
            this.$router.push('/lectures');
          } else {
            this.$store.dispatch("makePayment", course)
          }
        }
        if(course === "course4") {
          if(data.data().course4Purchase) {
            this.$router.push('/lectures');
          } else {
            this.$store.dispatch("makePayment", course)
          }
        }
      } else {
        this.$store.commit("updatePaymentFor", course)
        this.$store.commit("togglePopup", {popup: "coursePurchaseForm", status: true})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.coursePurchase {
  width: auto;
  height: auto;
  display: grid;
  // grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  gap: 3%;
  align-self: center;
  justify-self: center;
  margin: 3%;

  @media only screen and (min-width: 1024px) {
    margin: 5%;
  }

  @media only screen and (min-width: 1440px) {
    margin: 3%;
  }

  p {
    color: white;
  }

  &__courseWrapper {
    grid-row: 1/2;
    height: 100%;
    width: 100%;
    align-self: center;
    justify-self: center;
    display: grid;
    grid-template-columns: 1em auto 1em;
    grid-template-rows: 1em max-content max-content max-content 1em;
    border-radius: 1em;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;

    &:hover {
      border-color: white;
    }

    .courseImg {
      grid-column: 2/3;
      grid-row: 2/3;
      width: 100%;
    }
    .courseTitle {
      grid-column: 2/3;
      grid-row: 3/4;
      margin: 1em 0 0;
      font-weight: 900;
      font-size: 8pt;

      @media only screen and (min-width: 768px) {
        font-size: 10pt;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 12pt;
      }

      @media only screen and (min-width: 1440px) {
        font-size: 14pt;
      }
    }
    .courseDesc {
      grid-column: 2/3;
      grid-row: 4/5;
      margin: 0;
      font-size: 8pt;

      @media only screen and (min-width: 768px) {
        font-size: 8pt;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 10pt;
      }

      @media only screen and (min-width: 1440px) {
        font-size: 12pt;
      }
    }
  }
}
// .myCertWrapper {
//   grid-column: 1/2;
//   grid-row: 1/2;
//   height: 100%;
//   width: 100%;
//   align-self: center;
//   justify-self: center;
//   display: grid;
//   grid-template-columns: 100%;
//   grid-template-rows: 40% 70%;
// }
// .downloadWrapper {
//   grid-column: 2/3;
//   grid-row: 1/2;
//   height: 100%;
//   width: 100%;
//   align-self: center;
//   justify-self: center;
//   display: grid;
//   grid-template-columns: 100%;
//   grid-template-rows: 40% 70%;
//   opacity: 0.2;
// }
.certTitle {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  font-size: 26pt;
  font-weight: 500;
  margin: 0;
  color: white;
}
.downloadIcon {
  grid-column: 1/2;
  grid-row: 2/3;
  height: 185px;
  width: 230px;
  align-self: start;
  justify-self: center;
  cursor: pointer;
  background-image: url("~@/assets/download_icon_white.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s ease-in-out;
}
.certDownloadIcon:hover {
  background-image: url("~@/assets/download_icon2.svg");
}
.lectureDownloadIcon:hover {
  background-image: url("~@/assets/download_icon2.svg");
}

@media only screen and (max-height: 900px)  and (orientation: landscape) {
  // .coursePurchase {
  //   width: 75%;
  // }
  .certTitle {
    font-size: 20pt;
  }
  .downloadIcon {
    height: 70%;
    width: 70%;
  }
}
</style>
