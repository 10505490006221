<template>
  <div class="popupWrapper">
    <div class="popupContent">
      <h1 class="popupTitle" v-if="language === 'zh'">條款與細則</h1>
      <h1 class="popupTitle" v-if="language === 'en'">Terms & Conditions</h1>
      <img src="@/assets/close_btn_white.svg" class="closeBtn" @click="closePopup">
      <div class="popupTextWrapper">
        <h2 v-if="language === 'en'">Membership Terms</h2>
        <h2 v-if="language === 'zh'">會員條款</h2>
        <p v-if="language === 'en'">
          The ABBP Membership Programme (the “Programme”) is managed and operated by Asia Tattoo and Piercing Education Limited. All persons who have enrolled in and successfully passed the Asia Bloodborne Pathogen online course (ABBP) will be automatically granted membership to the ABBP. Services provided by all members must adhere to the Terms and Conditions outlined by their business. Upon the completion of member registration, the member accepts and agrees to be bound by the Terms and Conditions set by Asia Tattoo and Piercing Education Limited. 
        </p>
        <p v-if="language === 'zh'">
          會員計劃由亞洲人體藝術教育學院有限公司管理及經營。任何人士若參與亞洲人體藝術衞生管理課程 (ABBP) 並完成相關考核，將自動獲得此課程的會員資格。會員必須按照其服務條款和細則提供服務。會員完成註冊程序後，即表示會員與亞洲人體藝術教育學院有限公司達成協議，接納所有亞洲人體藝術教育學院有限公司所訂立的會員服務條款及細則。
        </p>

        <ol class="popupOrderList" v-if="language === 'en'">
          <li>
            Application Procedures
            <ol>
              <li>
                All persons who have enrolled in and successfully passed the Asia Bloodborne Pathogen online course (ABBP) will be granted membership of the ABBP, and enjoy the right to membership services thereon.
              </li>
              <li>
                Each member can only possess one membership number. Should there be any duplication of membership registration, the duplicated membership, including any membership benefits, will be removed without prior notice.
              </li>
              <li>
                Memberships of the ABBP are personal to the member and are not transferable.
              </li>
              <li>
                The Asia Tattoo and Piercing Education Limited reserves the right of final decision on the approval of each membership application. 
              </li>
            </ol>
          </li>
          <li>
            Revision of Terms and Conditions of Membership Service
            <ol>
              <li>
                The Asia Tattoo and Piercing Education, at any time and for whatever reason it may deem proper, reserves the right to modify the Programme, including the membership structure, offers and other relevant particulars. The Terms and Conditions are subject to change without prior notice. By using any services and offers provided by the Programme, the member accepts any possible changes and updates to the Programme. The Asia Tattoo and Piercing Education Limited is not responsible for any losses that may be caused by the necessary changes and updates to the Programme. In case of disputes, the Asia Tattoo and Piercing Education Limited reserves the right of final decision.
              </li>
              <li>
                Should members wish to engage in collaborations with third parties in the name of an ABBP member, they should submit a written request to the Asia Tattoo and Piercing Education Limited one month in advance. Members are prohibited from using the name of ABBP or Asia Tattoo and Piercing Education Limited in exchange for any personal benefits or offers provided by third parties.
              </li>
            </ol>
          </li>
          <li>
            Personal Information Collection Statement
            <ol>
              <li>
                The provision of your personal data is voluntary. All data collected by Asia Tattoo and Piercing Education Limited will be mainly used for the operation, management and communication of the Programme, and future business research and development. Should the member fail to provide or update his / her personal information, he / she may not be able to enjoy membership services and benefits offered by the Asia Tattoo and Piercing Education Limited, and may result in the termination of his / her membership. Any person who is unable to provide his / her full name, first four digits or letters of his / her Hong Kong Identity Card, contact number, email, office address, and Region / Country, will not be able to become a registered member.
              </li>
              <li>
                The ABBP will disclose the members’ business names, link(s) to their working profile, and membership number for public reference. Apart from the information stated above, Asia Tattoo and Piercing Education Limited will not disclose, edit or reveal any other personal information or non-public content collected by the Asia Tattoo and Piercing Education Limited’s ABBP course. All data collected will be used for the  purpose of the Programme, including but not limited to the issue of certificates, communication and provision of membership service. Members have the right to choose not to receive updates and news from the ABBP.
              </li>
              <li>
                Members have the responsibility to inform the ABBP about any changes to their personal information and new address. The Asia Tattoo and Piercing Education Limited is not responsible for any losses caused by the member’s failure to provide updated information. To update personal information, members should inform the ABBP through email at <a href="mailto:info@asiabbp.com">info@asiabb.com</a>. 
              </li>
            </ol>
          </li>
          <li>
            Notice
            <ol>
              <li>
                All notices to members will be announced via the official website, emails, letters or any public channels, including changes of terms and service or other important matters.
              </li>
              <li>
                The Asia Tattoo and Piercing Education Limited reserves the right to change the above terms and conditions without prior notice.
              </li>
              <li>
                In case of any disputes, the Asia Tattoo and Piercing Education Limited reserves the right of final decision.
              </li>
            </ol>
          </li>
          <li>
            Complimentary Benefits for ABBP members
            <ol>
              <li>
                All ABBP registered members are automatically granted the membership of the Hong Kong Body Modification and Tattoo Artist Association (BMTA(HK)) and eligibility to use their membership service. The membership period of the BMTA(HK) is the same as that of the ABBP.
              </li>
              <li>
                By becoming a member of the Hong Kong Body Modification and Tattoo Artist Association (BMTA(HK)), the member agrees to disclose his / her name of business on the BMTA(HK) website for the public’s reference. The Hong Kong Body Modification and Tattoo Artist Association reserves the right of final decision on all matters related to BMTA(HK) memberships.
              </li>
              <li>
                Should ABBP members wish to withdraw from the Hong Kong Body Modification and Tattoo Artist Association (BMTA(HK)), please inform the ABBP through email at <a href="mailto:info@asiabbp.com">info@asiabb.com</a>
              </li>
            </ol>
          </li>
          <li>
            Prevailing Language
            <ol>
              <li>
                In case of inconsistency between the English and Chinese versions of this letter, the [English] version shall prevail.
              </li>
            </ol>
          </li>
        </ol>

        <ol class="popupOrderList" v-if="language === 'zh'">
          <li>
            申請手續
            <ol>
              <li>
                任何人參與亞洲人體藝術教育學院有限公司其下的亞洲人體藝術衞生管理課程 (ABBP) 網上課程並完成相關考核將可獲得會藉，並可開始使用會員服務。
              </li>
              <li>
                會員只可同時擁有一個會員編號。如發現重複註冊，所有會藉包括有關會員福利會被同時刪除而不作另行通知。
              </li>
              <li>
                本會藉只供亞洲人體藝術衞生管理課程 (ABBP) 會員本人使用，並不可轉讓。
              </li>
              <li>
                亞洲人體藝術教育學院有限公司擁有最終批核會籍的決定權。
              </li>
            </ol>
          </li>
          <li>
            會員服務內容及條款的修改
            <ol>
              <li>
                亞洲人體藝術教育學院有限公司保留隨時更改會員制度的架構、優惠及其它項目的最終權利，包括條款及細則；或終止會員制度。如有任何更改，恕不另行通知。會員一經使用本計劃的任何項目及優惠，即表示該會員已接納任何有關的改動及更新。而因該有關改動及更新而可能導致的任何損失，亞洲人體藝術教育學院有限公司一概不負責。如有任何爭議，亞洲人體藝術教育學院有限公司保留一切最終決定權。
              </li>
              <li>
                若會員有需要使用亞洲人體藝術教育學院有限公司亞洲人體藝術衞生管理課程 (ABBP) 之會員身份與其他單位合作，須於一個月前向亞洲人體藝術教育學院有限公司亞洲人體藝術衞生管理課程（ABBP)作出書面申請。會員不可擅自使用亞洲人體藝術教育學院有限公司亞洲人體藝術衞生管理課程（ABBP)之會員身份從而獲得或接受個別單位所提供的福利及優惠。
              </li>
            </ol>
          </li>
          <li>
            收集個人資料聲明
            <ol>
              <li>
                會員提供的個人資料屬自願性質，將作亞洲人體藝術教育學院有限公司亞洲人體藝術衞生管理課程 (ABBP) 會員計劃運作及管理之用；或作為亞洲人體藝術教育學院有限公司日後資料搜集、發展計劃，以及為亞洲人體藝術衞生管理課程 (ABBP) 會員提供通訊和資料之用。若會員未能提供或更新有關資料，或會導致亞洲人體藝術教育學院有限公司未能為會員提供既定的會員服務及有關優惠，會籍可能會被終止。如未能提供姓名、身分證明文件頭四位字母及/或號碼、聯絡電話、電郵執業地址及地區/國家，將未能登記成為會員。
              </li>
              <li>
                亞洲人體藝術衞生管理課程 (ABBP) 會公開會員的藝名、工作連結及成員證書號數作公眾參考；但並不會公開、編輯或透露成員的其他個人資料及保存在亞洲人體藝術教育學院有限公司亞洲人體藝術衞生管理課程 (ABBP) 會員服務中的非公開內容；有關個人資料會用於但不限於為會員發放證書、送上最新消息、信息及提供服務。會員有權拒絕接收任何推廣信息。
              </li>
              <li>
                會員有責任通知亞洲人體藝術衞生管理課程 (ABBP) 最新的個人資料及通訊方法，如因會員沒有通知亞洲人體藝術衞生管理課程 (ABBP) 有關通訊方法的事宜而導致任何損失，亞洲人體藝術教育學院有限公司一概不負責。會員可直接電郵至<a href="mailto:info@asiabbp.com">info@asiabb.com</a>通知亞洲人體藝術衞生管理課程 (ABBP) 更改個人資料。
              </li>
            </ol>
          </li>
          <li>
            通告
            <ol>
              <li>
                所有發給會員的通告都將通過頁面的最新消息、電子郵件、信件或任何公眾形式傳送給會員，包括服務條款的修改、服務內容變更或其他重要事項。
              </li>
              <li>
                以上條款及細則，如有任何更改，恕不另行通知。
              </li>
              <li>
                如有任何爭議，亞洲人體藝術教育學院有限公司保留一切最終決定權。
              </li>
            </ol>
          </li>
          <li>
            額外會員優惠
            <ol>
              <li>
                所有會員將同時獲得香港紋身師及穿環師協會會藉，並可開始使用其會員服務。其香港紋身師及穿環師協會會藉的有效期和該會員的亞洲人體藝術衞生管理課程 (ABBP) 會藉相同。
              </li>
              <li>
                會員同意其藝名及工作連結在香港紋身師及穿環師協會的頁面公開以作公眾參考用途。香港紋身師及穿環師協會保留一切有關會藉的最終決定權。
              </li>
              <li>
                如會員不希望同時獲得香港紋身師及穿環師協會會藉， 請電郵至<a href="mailto:info@asiabbp.com">info@asiabb.com</a>通知亞洲人體藝術衞生管理課程 (ABBP) 再作安排。
              </li>
            </ol>
          </li>
          <li>
            準據語言
            <ol>
              <li>
                本條款的中文及英文版本如有任何歧義，概以[英文]版本為準。
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="popupButtonWrapper">
        <div class="popupBtn agreeBtn" @click="signUpPopupToggle">
          <p class="popupBtnText" v-if="language === 'zh'">同意</p>
          <p class="popupBtnText" v-if="language === 'en'">Agree</p>
        </div>
        <div class="popupBtn disagreeBtn" @click="closePopup">
          <p class="popupBtnText" v-if="language === 'zh'">不同意</p>
          <p class="popupBtnText" v-if="language === 'en'">Disagree</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  computed: {
    aboutPopup() {
      return this.$store.getters.aboutPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    closePopup() {
      this.$store.commit("togglePopup", {popup: "aboutPopup", status: false})
    },
    signUpPopupToggle() {
      if (!this.signUpFormPopup) {
        if (!this.signUpFormPopup) {
          if(this.loggedIn) {
            this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
            // this.$store.commit("accountPopup", true)
            // this.$store.commit("aboutPopup", false)
          }
          else {
            this.$store.commit("togglePopup", {popup: "signUpFormPopup", status: true})
            // this.$store.commit("signUpFormPopup", true)
            // this.$store.commit("aboutPopup", false)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/popupContent/popup-content.scss';
</style>
