import firebase from 'firebase';
import 'firebase/firestore';
import store from '../store';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
firebase.initializeApp(firebaseConfig);

function listenToUserData(email) {
  db.collection("user_data").where("email", "==", email)
  .onSnapshot((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const serverDate = doc.data().signUpDate.seconds;
      const expiryDate = doc.data().courseExpiryDate;
      const examDate = doc.data().examCompleteDate;
      let examCompleteDate = null;
      if(examDate) {
        examCompleteDate = new Date(examDate*1000).toLocaleDateString('en-GB');
      }
      const firstName = doc.data().englishName;
      const data = {
        'englishName': firstName || "",
        'studioName': doc.data().studioName,
        'signUpDate': new Date(serverDate*1000).toLocaleDateString('en-GB'),
        'courseExpiryDate': new Date(expiryDate*1000).toLocaleDateString('en-GB'),
        'lectureProgress': doc.data().lectureProgress,
        'lectureComplete': doc.data().lectureComplete,
        'examStatus': doc.data().examStatus,
        'examResult': doc.data().examResult,
        'examComplete': doc.data().examComplete,
        'payment': doc.data().payment,
        'examTempScore': doc.data().examTempScore,
        'examCompleteDate': examCompleteDate
      }
      store.dispatch('updateUserData', data)
      if(doc.data().lectureComplete !== true && doc.data().lectureProgress === "100" || doc.data().lectureProgress === 100) {
        enableExam(email)
      }
    })
  }, (err) => {
    console.log("Error getting realtime data: ", err);
  })
}

function enableExam(email) {
  db.collection("user_data").doc(email)
  .update({
    "lectureComplete": true
  })
  .then(() => {
    console.log("Lecture status updated");
  })
  .catch((err) => {
    console.log("Lecture status updated: " + err);
  })
}

firebase.auth().onAuthStateChanged((user) => {
  if(user) {
    store.commit('updateLoginStatus', true);
    store.commit("updateUserEmail", user.email);
    // fetchUserData(user.email);
    listenToUserData(user.email);
  }
  else {
    store.commit('updateLoginStatus', false);
  }
});

export const db = firebase.firestore();
