<template>
  <div class="resetPopup">
    <!-- <img src="@/assets/closeBtn.svg" class="resetCloseBtn" @click="resetCloseBtn"> -->
    <div class="resetInputWrapper" ref="resetInputWrapper">
      <div class="resetText">
        <h1>
          Reset Your Password
        </h1>
        <img src="@/assets/closeBtn.svg" class="resetCloseBtn" @click="resetCloseBtn">
      </div>
      <div class="emailInputWrapper">
        <p>Email</p>
        <input class="emailTextarea" ref="emailTextarea" type="email" @keyup.enter="resetPassword" v-model="email">
      </div>
      <div class="resetBtnWrapper">
        <div class="btn resetBtn" @click="resetPassword">
          <p class="resetBtnText">RESET</p>
        </div>
        <div class="btn resetSignUpBtn" @click="backToLogin">
          <p class="resetSignUpBtnText">BACK</p>
        </div>
      </div>
    </div>
    <div class="resetSuccessWrapper hidden" ref="resetSuccessWrapper">
      <div class="resetText">
        <p>
          Reset email has been sent.
        </p>
        <img src="@/assets/closeBtn.svg" class="resetCloseBtn" @click="resetCloseBtn">
      </div>
      <img src="@/assets/tick_icon.svg" class="resetTickIcon">
      <div class="resetSuccessBtnWrapper">
        <div class="resetBtn" @click="backToLogin">
          <p class="resetBtnText">Login</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as firebase from "firebase/app";
import firebase from "firebase";
// import "firebase/auth";
export default {
  name: 'PasswordRestPopup',
  data() {
    return {
      email: ""
    }
  },
  computed: {
    loginPopup() {
      return this.$store.getters.loginPopup;
    }
  },
  methods: {
    resetCloseBtn() {
      this.$store.commit("togglePopup", {popup: "passwordResetPopup", status: false})
    },
    backToLogin() {
      this.$store.commit("togglePopup", {popup: "loginPopup", status: true})
    },
    resetPassword() {
      let auth = firebase.auth();
      let email = this.email;

      auth.sendPasswordResetEmail(email).then(() => {
        this.$refs.resetInputWrapper.classList.add('hidden');
        this.$refs.resetSuccessWrapper.classList.remove('hidden');
      }).catch(err => {
        console.log(err);
        alert(err);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.resetPopup {
  /* height: 100%;
  width: 100%; */
  background-color: white;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
.resetInputWrapper {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 25% 45% 30%;
}
.resetText {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;

  h1 {
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
    align-self: center;
    font-size: 18pt;
    font-weight: bold;
    margin: 6% 0 0 0;
    z-index: 0;

    @media only screen and (min-width: 768px) {
      font-size: 22pt;
    }
  }
} 
.resetCloseBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  height: 20px;
  margin: 8% 8% 0 0;
  cursor: pointer;
  z-index: 20;

  @media only screen and (min-width: 768px) {
    height: 25px;
    margin: 6% 6% 0 0;
  }
}
.emailInputWrapper {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 70%;
  justify-self: center;
  /* align-self: center; */
  display: grid;

  p {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: 12pt;
    text-align: left;
    margin: 0 0 0.5em 0.5em;
    align-self: end;

    @media only screen and (min-width: 768px) {
      font-size: 15pt;
    }
  }
}
.emailTextarea {
  grid-column: 1/2;
  grid-row: 2/3;
  resize: none;
  border-style: solid;
  border-width: 2pt;
  border-radius: 1em;
  width: 100%;
  height: 45px;
  font-size: 14pt;
  align-self: start;
  padding: 0 3% 0 3%;
}
.resetBtnWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  width: 70%;
  justify-self: center;
}
.btn {
  width: 100px;
  height: 38px;

  p {
    font-size: 10pt;
  }

  @media only screen and (min-width: 768px) {
    width: 130px;
    height: 46px;

    p {
      font-size: 12pt;
    }
  }
}
.resetBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  background-color: #3BC146;
  border-style: solid;
  border-width: 2pt;
  border-radius: 3.5em;
  border-color: #3BC146;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.resetBtn:hover {
  background-color: black;
  border-color: black;
  /* color: white; */
}
.resetSignUpBtn {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  background-color: white;
  border-style: solid;
  border-width: 2pt;
  border-radius: 3.5em;
  display: grid;
  cursor: pointer;
  color: black;
  transition: 0.3s ease-in-out;
}
.resetSignUpBtn:hover {
  background-color: black;
  border-color: black;
  color: white;
}
.resetBtnText {
  justify-self: center;
  align-self: center;
  color: white;
  font-size: 12pt;
  margin: 0;
}
.resetSignUpBtnText {
  justify-self: center;
  align-self: center;
  /* color: black; */
  font-size: 12pt;
  margin: 0;
}
.resetSuccessWrapper {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 25% 45% 30%;
}
.resetSuccessBtnWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  width: 70%;
  justify-self: center;
}
.resetTickIcon {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 150px;
  justify-self: center;
  align-self: center;
  display: grid;
}
.hidden {
  display: none;
}
</style>
