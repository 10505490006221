import Vue from "vue";
import Vuex from "vuex";
import main from "./modules/main";

// create connection ------------ //
Vue.use(Vuex);

// collection of all modules --------------//
// allowing access from vue
export default new Vuex.Store({
  modules: {
    main
  },
});
