<template>
  <div class="CertAccountWrapper">
    <div class="myCertWrapper">
      <p class="certTitle" v-if="language === 'zh'">我的證書</p>
      <p class="certTitle" v-if="language === 'en'">My Certificate</p>
      <div class="downloadIcon certDownloadIcon" @click="downloadCert">
      </div>
    </div>
    <div class="downloadWrapper">
      <p class="certTitle" v-if="language === 'zh'">範本</p>
      <p class="certTitle" v-if="language === 'en'">Templates</p>
      <div class="downloadIcon lectureDownloadIcon" @click.prevent="downloadTemplate">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'CertificateAccount',
  data() {
    return {
      
    }
  },
  computed: {
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    downloadCert() {
      this.$router.push('/certificate');
    },
    downloadTemplate() {
      axios
      .get('/assets/ABBP_templates.zip', { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'abbp_templates.zip';
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
    }
  },
}
</script>

<style lang="scss" scoped>
.CertAccountWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  align-self: center;
  justify-self: center;

  @media only screen and (min-width: 768px) {
    width: 70%;
    height: 60%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
  }
}
.myCertWrapper {
  grid-column: 1/2;
  grid-row: 1/2;
  height: auto;
  width: 100%;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 40% 70%;

  @media only screen and (min-width: 768px) {
    height: 100%;
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
.downloadWrapper {
  grid-column: 1/2;
  grid-row: 2/3;
  height: auto;
  width: 100%;
  align-self: start;
  justify-self: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 40% 70%;

  @media only screen and (min-width: 768px) {
    height: 100%;
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: center;
  }
}
.certTitle {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  font-size: 18pt;
  font-weight: 500;
  margin: 0;
  color: white;

  @media only screen and (min-width: 1028px) {
    font-size: 20pt;
  }

  @media only screen and (min-width: 1028px) {
    font-size: 26pt;
  }
}
.downloadIcon {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 10em;
  height: 8em;
  align-self: start;
  justify-self: center;
  cursor: pointer;
  background-image: url("~@/assets/download_icon_white.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s ease-in-out;

  @media only screen and (min-width: 768px) {
    width: 11em;
    height: 9em;
  }

  @media only screen and (min-width: 1024px) {
    height: 185px;
    width: 230px;
  }
}
.certDownloadIcon:hover {
  background-image: url("~@/assets/download_icon2.svg");
}
.lectureDownloadIcon:hover {
  background-image: url("~@/assets/download_icon2.svg");
}
// @media only screen and (max-height: 900px)  and (orientation: landscape) {
//   .CertAccountWrapper {
//     width: 75%;
//   }
//   .certTitle {
//     font-size: 20pt;
//   }
//   .downloadIcon {
//     height: 70%;
//     width: 70%;
//   }
// }
</style>
