<template>
  <!-- <div class="signUpScrollWrapper"> -->
    <div class="signUpWrapper">
      <div class="signUpContent">
        <div class="signUpTitle">
          <h2 v-if="language === 'zh'">註冊</h2>
          <h2 v-if="language === 'en'">SIGN UP</h2>
        </div>
        <img src="@/assets/close_btn_white.svg" class="signUpCloseBtn" @click="signUpCloseBtn">
        <div class="signUpInputWrapper">
          <div class="emailWrapper inputWrapper">
            <p class="inputTitle" v-if="language === 'zh'">電郵</p>
            <p class="inputTitle" v-if="language === 'en'">E-mail</p>
            <input class="emailInput textInput" ref="emailInput" name="email" type="email" v-model="email">
            <div class="warningTextWrapper">
              <p class="warningText" v-if="language === 'zh'" v-show="emailWarningText">* 此格為空</p>
              <p class="warningText" v-if="language === 'en'" v-show="emailWarningText">* This filed is empty</p>
              <p class="warningText" v-show="emailWarningText2">* Please enter a valid email address.</p>
            </div>
          </div>
          <div class="confirmEmailWrapper inputWrapper">
            <p class="inputTitle" v-if="language === 'zh'">確認電郵</p>
            <p class="inputTitle" v-if="language === 'en'">Confirm E-mail</p>
            <input class="confirmEmailInput textInput" ref="confirmEmailInput" name="confirmEmail" type="email" v-model="confirmEmail">
            <div class="warningTextWrapper">
              <p class="warningText" v-if="language === 'zh'" v-show="confirmEmailWarningText">* 此格為空</p>
              <p class="warningText" v-if="language === 'en'" v-show="confirmEmailWarningText">* This filed is empty</p>
              <p class="warningText" v-show="confirmEmailWarningText2">* This does not match your email above.</p>
            </div>
          </div>
          <div class="passwordWrapper inputWrapper">
            <p class="inputTitle" v-if="language === 'zh'">密碼</p>
            <p class="inputTitle" v-if="language === 'en'">Password</p>
            <input class="passwordInput textInput" ref="passwordInput" name="password" type="password" v-model="password">
            <div class="warningTextWrapper">
              <p class="warningText2" v-if="language === 'zh'">密碼必須8位數以上。</p>
              <p class="warningText2" v-if="language === 'en'">Password combination must be at least 8 characters.</p>
              <p class="warningText" v-if="language === 'zh'" v-show="passwordWarningText">* 請輸入有效的密碼</p>
              <p class="warningText" v-if="language === 'en'" v-show="passwordWarningText">* Please enter a valid password.</p>
              <p class="warningText" v-if="language === 'zh'" v-show="passwordWarningText2">* 密碼必須起碼8位數。</p>
              <p class="warningText" v-if="language === 'en'" v-show="passwordWarningText2">* Password must have at least 8 letters.</p>
            </div>
          </div>
          <div class="confirmPasswordWrapper inputWrapper">
            <p class="inputTitle" v-if="language === 'zh'">確認密碼</p>
            <p class="inputTitle" v-if="language === 'en'">Confirm Password</p>
            <input class="confirmPasswordInput textInput" ref="confirmPasswordInput" name="confirmPassword" type="password" v-model="confirmPassword">
            <div class="warningTextWrapper">
              <p class="warningText" v-if="language === 'zh'" v-show="confirmPasswordWarningText">* 此格為空</p>
              <p class="warningText" v-if="language === 'en'" v-show="confirmPasswordWarningText">* This filed is empty</p>
              <p class="warningText" v-show="confirmPasswordWarningText2">* This does not match your password above.</p>
            </div>
          </div>
        </div>
        <div class="signUpBtnWrapper">
          <div class="signUpBtn" @click="submitSignUpForm">
            <p class="signUpBtnText" v-if="language === 'zh'">註冊</p>
            <p class="signUpBtnText" v-if="language === 'en'">SIGN UP</p>
          </div>
          <div class="signUpCancelBtn" @click="signUpCloseBtn">
            <p class="signUpBtnText" v-if="language === 'zh'">取消</p>
            <p class="signUpBtnText" v-if="language === 'en'">CANCEL</p>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import firebase from "firebase";
import { db } from '@/components/firebase.js';
export default {
  name: 'SignUpForm',
  data() {
    return {
      firstNameWarningText: false,
      lastNameWarningText: false,
      emailWarningText: false,
      emailWarningText2: false,
      confirmEmailWarningText: false,
      confirmEmailWarningText2: false,
      passwordWarningText: false,
      passwordWarningText2: false,
      confirmPasswordWarningText: false,
      confirmPasswordWarningText2: false,
      studioNameWarningText: false,
      studioAddressWarningText: false,
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      studioName: "",
      studioAddress: "",
      instagram: "",
      facebook: "",
      mewe: "",
      checkEmail: false,
      checkPassword: false,
    }
  },
  computed: {
    paymentPopup() {
      return this.$store.getters.paymentPopup;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    signUpCloseBtn() {
      this.$store.commit("togglePopup", {popup: "signUpFormPopup", status: false})
    },
    allInputChecker() {
      let allInputCheck;
      this.emailWarningText = false;
      this.emailWarningText2 = false;
      this.confirmEmailWarningText = false;
      this.confirmEmailWarningText2 = false;
      this.passwordWarningText = false;
      this.passwordWarningText2 = false;
      this.confirmPasswordWarningText = false;
      this.confirmPasswordWarningText2 = false;
      // const otherInputCheck = this.submitEmptyCheck();
      const emailCheck = this.emailCheck();
      const confirmEmailCheck = this.confirmEmailCheck();
      const passwordCheck = this.passwordCheck();
      const confirmPasswordCheck = this.confirmPasswordCheck();

      if(emailCheck && confirmEmailCheck && passwordCheck && confirmPasswordCheck) {
        allInputCheck = true;
        return allInputCheck;
      }
    },
    submitEmptyCheck() {
      const textInput = document.querySelectorAll('.textInput');
      let firstNameCheck;
      let lastNameCheck;
      let studioNameCheck;
      let addressCheck;
      let otherInputCheck;
      // let LetterRegExp = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
      textInput.forEach(input => {
        if(!input.value) {
          console.log(input.name + "Input field empty");
          if(input.name === "firstName") {
            this.firstNameWarningText = true;
          }
          if(input.name === "lastName") {
            this.lastNameWarningText = true;
          }
          if(input.name === "studioName") {
            this.studioNameWarningText = true;
          }
          if(input.name === "studioAddress") {
            this.studioAddressWarningText = true;
          }
        }
        else {
          if(input.name === "firstName") {
            firstNameCheck = true;
            return firstNameCheck;
          }
          if(input.name === "lastName") {
            lastNameCheck = true;
            return lastNameCheck;
          }
          if(input.name === "studioName") {
            studioNameCheck = true;
            return studioNameCheck;
          }
          if(input.name === "studioAddress") {
            addressCheck = true;
            return addressCheck;
          }
        }
      })
      if(firstNameCheck && lastNameCheck && studioNameCheck && addressCheck) {
        otherInputCheck = true;
        return otherInputCheck;
      }
    },
    emailCheck() {
      // let LetterRegExp = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
      let emailCheck;
      if(!this.email) {
        this.emailWarningText = true;
      }
      else {
        if(!this.email.includes("@") || !this.email.includes(".")) {
          this.emailWarningText2 = true;
        }
        else {
          emailCheck = true;
          this.checkEmail = true;
          return emailCheck;
        }
      }
    },
    confirmEmailCheck() {
      let confirmEmailCheck;
      if(this.confirmEmail === this.email) {
        if(!this.confirmEmail) {
          this.confirmEmailWarningText = true;
        }
        else {
          confirmEmailCheck = true;
          return confirmEmailCheck;
        }
      }
      else {
        this.confirmEmailWarningText2 = true;
      }
    },
    passwordCheck() {
      // let LetterRegExp = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
      let passwordCheck;
      if(!this.password) {
        this.passwordWarningText = true;
      }
      else {
        if(this.password.length < 8) {
          this.passwordWarningText2 = true;
        }
        else {
          passwordCheck = true;
          this.checkPassword = true;
          return passwordCheck;
        }
      }
    },
    confirmPasswordCheck() {
      let confirmPasswordCheck;
      if(this.confirmPassword === this.password) {
        if(!this.confirmPassword) {
          this.confirmPasswordWarningText = true;
        }
        else {
          confirmPasswordCheck = true;
          return confirmPasswordCheck;
        }
      }
      else {
        this.confirmPasswordWarningText2 = true;
      }
    },
    async uploadUserData() {
      // let options = { day: 'numeric', month: 'long', year: 'numeric' };
      const timestamp = firebase.firestore.Timestamp.now();
      // const today = new Date();
      // const month = today.getMonth();
      let expiryDate = timestamp.seconds + 5259486;

      this.$store.state.main.expiryDate = timestamp;
      const dataBase = await db.collection("user_data").doc(this.email);
      const userData = await dataBase.get();
      if(userData.exists) {
        dataBase.update({
          email: this.email,
          signUpDate: timestamp,
          courseExpiryDate: expiryDate,
          course1Purchase: false,
          course2Purchase: false,
          course3Purchase: false,
          course4Purchase: false,
          lectureProgress: 0,
          lectureComplete: false,
          examStatus: 0,
          examResult: 0,
          examComplete: userData.data().examComplete ? true : false,
          examCompleteDate: null,
          payment: userData.data().payment ? true : false,
          examTempScore: 0,
        })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("%cError writing document: ", error,"color:red;font-size:15pt;");
        });
      } else {
        dataBase.set({
          email: this.email,
          signUpDate: timestamp,
          courseExpiryDate: expiryDate,
          course1Purchase: false,
          course2Purchase: false,
          course3Purchase: false,
          course4Purchase: false,
          lectureProgress: 0,
          lectureComplete: false,
          examStatus: 0,
          examResult: 0,
          examComplete: false,
          examCompleteDate: null,
          payment: false,
          examTempScore: 0,
        })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("%cError writing document: ", error,"color:red;font-size:15pt;");
        });
      }
    },
    async submitSignUpForm() {
      const allInputCheck = this.allInputChecker();
      if(allInputCheck) {
        try {
          const newUser = await firebase.auth().createUserWithEmailAndPassword(this.email, this.password);
          this.uploadUserData();
          // this.$store.commit("togglePopup", {popup: "signUpFormPopup", status: false})
          // this.$store.commit("togglePopup", {popup: "paymentPopup", status: true})
          return newUser;
        } 
        catch(err) {
          let errorCode = err.code;
          if(errorCode == 'auth/email-already-in-use') {
            alert("This Email is already in use.");
          }
        }
      }
      else {
        console.log("%cInput check didn't pass","color:red;font-size:15pt;");
      }
    },
    paymentPopupToggle() {
      const allInputCheck = this.allInputChecker();
      if(allInputCheck) {
        if (!this.paymentPopup) {
          this.$store.commit("paymentPopup", true)
        }
      }
      else {
        console.log("%cInput check didn't pass","color:red;font-size:15pt;");
      }
      
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/form/form.scss';
</style>
