<template>
  <div class="menu">
    <div class="logo">
      <img src="@/assets/logo.svg" @click="goHome">
    </div>
    <menu-items class="desktopMenu" />
    <div class="mobileMenuWrapper" v-if="this.menuPopup">
      <menu-items class="mobileMenu" />
    </div>
    <menu-icon class="mobileMenuIcon" @click="openMenu" />
  </div>
</template>

<script>
import MenuIcon from './MenuIcon.vue';
import MenuItems from './MenuItems.vue';
export default {
  components: { 
    MenuIcon,
    MenuItems 
  },
  name: 'Menu',
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    menuPopup() {
      return this.$store.getters.menuPopup;
    },
    aboutPopup() {
      return this.$store.getters.aboutPopup;
    },
    loginPopup() {
      return this.$store.getters.loginPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    accountPopup() {
      return this.$store.getters.accountPopup;
    },
    contactPopup() {
      return this.$store.getters.contactPopup;
    },
    paymentPopup() {
      return this.$store.getters.paymentPopup;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    togglePopup(popup) {
      this.$store.commit("togglePopup", {popup: popup, status: true})
    },
    goHome() {
      this.$store.commit("closeAllPopup");
    },
    openMenu() {
      console.log('menuButton');
      this.showMenu = !this.showMenu;
    },
    coursePopup() {
    if(this.loggedIn) {
        this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
        this.$store.commit('accountPage', "lecture");
      }
      else {
        this.$store.commit("togglePopup", {popup: "termsAndConditionsPopup", status: true})
      }
    },
    paymentPopupToggle() {
      if (!this.paymentPopup) {
        this.$store.commit("paymentPopup", true);
      }
    },
    languageChange() {
      if (this.language === "en") {
        this.$store.commit("changeLanguage", "zh");
      }
      else {
        this.$store.commit("changeLanguage", "en");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  height: 6.5em;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 15% auto auto 1em;
  grid-template-rows: 100%;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 13% auto auto 2%;
  }
}
.logo {
  grid-column: 1/2;
  display: grid;
}
.logo img {
  height: 3.5em;
  align-self: center;
  justify-self: center;
  margin: 0 0 0 2em;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    margin: 0 0 0 1.5em;
    height: 4em;
  }

  @media only screen and (min-width: 960px) {
    margin: 0 0 0 1.5em;
  }

  @media only screen and (min-width: 1440px) {
    height: 4.5em;
  }
}

.desktopMenu {
  flex-direction: row;
  justify-content: end;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.mobileMenuWrapper {
  height: 100vh;
  width: 100vw;
  background-color: #202125;
  position: fixed;

  @media only screen and (max-height: 545px) and (orientation: landscape) {
    overflow-y: scroll;
  }

  .mobileMenu {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    @media only screen and (max-height: 545px) and (orientation: landscape) {
      padding: 7.2em 0 6.3em;
    }
  }
}

.mobileMenuIcon {
  grid-column: 3/4;
  display: flex;
  justify-self: end;
  align-self: center;
  @media only screen and (min-width: 769px) {
    display: none;
  }
}
</style>
