<template>
  <div class="callToAction">
    <div class="homeText">
      <p v-if="language === 'zh'">
        關注紋身師、穿環師、紋繡師等人體藝術行業的職業安全健康
      </p>
      <p v-if="language === 'en'">
        Occupational safety and health
        for tattooists, piercers, cosmetic tattoo specialists,
        body modification practitioners and more.
      </p>
    </div>
    <div class="homeBtnWrapper">
      <div class="aboutBtn button" @click="aboutPopupToggle">
        <p v-if="language === 'zh'">瞭解更多</p>
        <p v-if="language === 'en'">LEARN MORE</p>
      </div>
      <!-- <div class="certBtn button" @click="loginPopupToggle">
        <p v-if="language === 'zh'">證書</p>
        <p v-if="language === 'en'">CERTIFICATES</p>
      </div> -->
      <div class="signUpBtn button" @click="signUpPopupToggle">
        <p v-if="language === 'zh'">現在就註冊</p>
        <p v-if="language === 'en'">SIGN UP NOW</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCallToAction',
  computed: {
    aboutPopup() {
      return this.$store.getters.aboutPopup;
    },
    signUpFormPopup() {
      return this.$store.getters.signUpFormPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    aboutPopupToggle() {
      this.$store.commit("togglePopup", {popup: "aboutPopup", status: true})
    },
    loginPopupToggle() {
      this.$store.commit("togglePopup", {popup: "loginPopup", status: true})
    },
    signUpPopupToggle() {
      if (!this.signUpFormPopup) {
        if(this.loggedIn) {
          this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
        }
        else {
          // this.$store.commit("togglePopup", {popup: "signUpFormPopup", status: true})
          this.$store.commit("togglePopup", {popup: "termsAndConditionsPopup", status: true})
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.callToAction {
  width: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;

  @media only screen and (min-width: 768px) {
    width: 420px;
  }

  @media only screen and (min-width: 1024px) {
    width: 520px;
  }
}
.homeText {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  color: white;
  align-self: center;
  text-align: left;
  font-size: 12pt;

  @media only screen and (min-width: 768px) {
    font-size: 16pt;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 18pt;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 20pt;
  }
}
.homeBtnWrapper {
  grid-column: 1/2;
  grid-row: 2/3;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 100%;
  gap: 1em;
  color: white;

  @media only screen and (min-width: 768px) {
    // font-size: 16pt;
  }
}

.button {
  height: 40px;
  width: 130px;
  background-color: #F66F51;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: black;
  }

  @media only screen and (min-width: 1024px) {
    height: 46px;
    width: 160px;
  }

  // @media only screen and (min-width: 1440px) {
  //   height: 42px;
  //   width: 220px;
  // }
}

.aboutBtn {
  // grid-column: 1/2;
  grid-row: 1/2;
  justify-self: start;  
}

.certBtn {
  // grid-column: 2/3;
  grid-row: 1/2;
  justify-self: center;
}

.signUpBtn {
  // grid-column: 2/3;
  grid-row: 1/2;
  justify-self: end;
}

.homeBtnWrapper p {
  padding: 0;
  margin: 0;
  justify-self: center;
  align-self: center;
  font-size: 10pt;

  @media only screen and (min-width: 1440px) {
    font-size: 12pt;
  }
}
</style>
