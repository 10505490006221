<template>
  <div class="loading">
  </div>
  </template>
  
  <script>
  export default {
    name: 'Loading',
  }
  </script>
  
  <style lang="scss" scoped>
  $color: #202125;
  .loading {
    display: block;
    width: 6em;
    height: 5em;
    border-radius: 50%;
    background-color: $color;
    position: relative;
    box-sizing: border-box;
    background-color:transparent;
    opacity: .8;

    &:before, &:after {
      content:'';
      display: block;
      position: absolute;
      height: 1em;
      width: 100%;
      border-radius: 10px;
      background-color: $color;
      top: 50%;
      left: 0;
      animation: width 1s linear infinite;
    }
    &:after {
      animation-delay: -0.3s;
    }
  }

  @keyframes circle0 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes circle1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes circle2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes width {
    0% {
      width: 0;
      left: 50%;
      opacity: 1;
    }
    90% {
      width: 100%;
      left: 0%;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes height1 {
    0% {
      height: 30%;
    }
    50% {
      height: 50%;
    }
    100% {
      height: 30%;
    }
  }
  @keyframes height2 {
    0% {
      height: 50%;
    }
    50% {
      height: 30%;
    }
    100% {
      height: 50%;
    }
  }
  @keyframes height3 {
    0% {
      height: 20%;
    }
    50% {
      height: 70%;
    }
    100% {
      height: 20%;
    }
  }
  @keyframes transform_scale {
    0% {
      transform: scale(0,0); 
    }
    100% {
      transform: scale(1,1);
      opacity: 0;
    }
  }

  @keyframes loading-bar {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes item7-dot {
    0% {
      opacity: 1;
      top: 50%;
    }
    50% {
      opacity: 0.3;
      top: 0;
    }
    100% {
      opacity: 1;
      top: 50%;
    }
  }
  </style>
  