<template>
  <div class="accountWrapper">
    <div class="accountBtnWrapper">
      <div class="overview accountBtn" ref="overview" @click="changeAccountPage('overview')">
        <img src="@/assets/overview_icon_white.svg" class="overviewIcon accountIcon">
        <p class="accountText" v-if="language === 'zh'">概述</p>
        <p class="accountText" v-if="language === 'en'">Overview</p>
      </div>
      <div class="lecture accountBtn" ref="lecture" @click="changeAccountPage('lecture')">
        <img src="@/assets/lecture_icon_white.svg" class="lectureIcon accountIcon">
        <p class="accountText" v-if="language === 'zh'">課程</p>
        <p class="accountText" v-if="language === 'en'">Course</p>
      </div>
      <div class="exam accountBtn" ref="exam" @click="changeAccountPage('exam')">
        <img src="@/assets/exam_icon_white.svg" class="examIcon accountIcon">
        <p class="accountText" v-if="language === 'zh'">考試</p>
        <p class="accountText" v-if="language === 'en'">Exam</p>
      </div>
      <div class="certificate accountBtn disable" ref="certificate" @click="changeAccountPage('certificate')">
        <img src="@/assets/cert_icon_white.svg" class="certificateIcon accountIcon">
        <p class="accountText" v-if="language === 'zh'">我的證書</p>
        <p class="accountText" v-if="language === 'en'">My Certificate</p>
      </div>
      <div class="logout accountBtn" @click="logout">
        <img src="@/assets/logout_icon.svg" class="logoutIcon accountIcon">
        <p class="accountText" v-if="language === 'zh'">登出</p>
        <p class="accountText" v-if="language === 'en'">Logout</p>
      </div>
    </div>
    <div class="accountContentWrapper">
      <img src="@/assets/close_btn_white.svg" class="accountCloseBtn" @click="accountCloseBtn">
      <!-- <div class="noPaymentWarningWrapper" v-if="!this.userData.payment">
        <div class="noPaymentWarningBg"></div>
        <div class="noPaymentWarning">
          <p class="noPaymentWarningText">
            Payment has not been made yet. To access the lecture, please complete your payment.
          </p>
          <div class="noPaymentWarningBtn" @click="makePayment">
            <p>Make Payment</p>
          </div>
        </div>
      </div> -->
      <Overview class="accountContent" v-if="accountPage === 'overview'" />
      <LectureAccount class="accountContent" v-if="accountPage === 'lecture'" />
      <CoursePurchase class="accountContent" v-if="accountPage === 'coursePurchase'" />
      <ExamAccount class="accountContent" v-if="accountPage === 'exam'" />
      <CertificateAccount class="accountContent" v-if="accountPage === 'certificate'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Overview from './Overview.vue';
import LectureAccount from './LectureAccount.vue';
import CoursePurchase from './CoursePurchase.vue';
import ExamAccount from './ExamAccount.vue';
import CertificateAccount from './CertificateAccount.vue';
export default {
  name: 'Account',
  data() {
    return {
      // selected: "overview"
    }
  },
  components: {
    Overview,
    LectureAccount,
    ExamAccount,
    CertificateAccount,
    CoursePurchase
  },
  computed: {
    ...mapGetters([
      'getAccountPage',
      'getUserEmail',
      'getUserData',
      'getLanguage'
    ]),

    accountPage() {
      return this.$store.getters.getAccountPage;
    },
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    // fetchUserData() {
    //   db.collection("user_data").where("email", "==", this.userEmail)
    //   .get()
    //   .then(data => {
    //     data.forEach(doc => {
    //       const serverDate = doc.data().signUpDate.seconds;
    //       const converDate = new Date(serverDate*1000);
    //       const expiryDate = doc.data().courseExpiryDate;
    //       const data = {
    //         'firstName': doc.data().firstName,
    //         'lastName': doc.data().lastName,
    //         'studioName': doc.data().studioName,
    //         'signUpDate': converDate.toLocaleDateString('en-GB'),
    //         'courseExpiryDate': new Date(expiryDate*1000).toLocaleDateString('en-GB'),
    //         'lectureProgress': doc.data().lectureProgress,
    //         'lectureComplete': doc.data().lectureComplete,
    //         'examStatus': doc.data().examStatus,
    //         'examResult': doc.data().examResult,
    //         'examComplete': doc.data().examComplete,
    //       }
    //       this.$store.commit('updateUserData', data);
    //       // this.userData = data;
    //       this.rawUserData = doc.data();
    //     })
    //   })
    //   .catch(err => {
    //       console.log("Error getting documents: ", err);
    //   });
    // },
    accountCloseBtn() {
      this.$store.commit("togglePopup", {popup: "accountPopup", status: false})
    },
    logout() {
      this.$store.dispatch('logout')

      // firebase
      // .auth()
      // .signOut()
      // .then(() => {
      //   this.$store.commit("togglePopup", {popup: "accountPopup", status: false})
      //   // this.$store.commit("accountPopup", false)
      //   console.log("Logged Out");
      // })
      // .catch(err => {
      //   console.log(err);
      //   alert("err");
      // });
    },
    checkCurrentPage() {
      if(this.accountPage === "overview") {
        this.$refs.overview.classList.add('selected');
        this.$refs.lecture.classList.remove('selected');
        this.$refs.exam.classList.remove('selected');
        this.$refs.certificate.classList.remove('selected');
      }
      if(this.accountPage === "lecture" || this.accountPage === "coursePurchase") {
        this.$refs.overview.classList.remove('selected');
        this.$refs.lecture.classList.add('selected');
        this.$refs.exam.classList.remove('selected');
        this.$refs.certificate.classList.remove('selected');
      }
      if(this.accountPage === "exam") {
        this.$refs.overview.classList.remove('selected');
        this.$refs.lecture.classList.remove('selected');
        this.$refs.exam.classList.add('selected');
        this.$refs.certificate.classList.remove('selected');
      }
      if(this.accountPage === "certificate") {
        this.$refs.overview.classList.remove('selected');
        this.$refs.lecture.classList.remove('selected');
        this.$refs.exam.classList.remove('selected');
        this.$refs.certificate.classList.add('selected');
      }
    },
    changeAccountPage(data) {
      // console.log("Account Page: " + data);
      if(data === "certificate") {
        if(this.userData.examComplete) {
          this.$store.commit('accountPage', data);
        }
        else {
          console.log("You need to complete the exam first");
        }
      }
      else if(data === "exam") {
        if(this.userData.lectureComplete && !this.userData.examComplete) {
          this.$store.commit('accountPage', data);
        }
        else {
          console.log("You need to complete the lecture first");
        }
      }
      else if(data === "overview") {
        if(this.userData.payment) {
          this.$store.commit('accountPage', data);
        }
        else {
          console.log("You need to complete the lecture first");
        }
      }
      else if(data === "lecture") {
        this.$store.commit('accountPage', 'lecture');

        // if(this.userData.payment) {
        //   this.$store.commit('accountPage', data);
        // }
        // else {
        //   console.log("You need to complete the payment first");
        //   this.$store.commit('accountPage', 'coursePurchase');
        // }
      }
      else {
        this.$store.commit('accountPage', data);
      }
    },
    checkDisable() {
      if(this.userData.examComplete) {
        this.$refs.certificate.classList.remove('disable');
      }
      if(!this.userData.examComplete) {
        this.$refs.certificate.classList.add('disable');
      }
      if(this.userData.lectureComplete) {
        this.$refs.exam.classList.remove('disable');
      }
      if(!this.userData.lectureComplete || this.userData.examComplete) {
        this.$refs.exam.classList.add('disable');
      }
      if(!this.userData.payment) {
        // this.$refs.lecture.classList.add('disable');
        this.$refs.overview.classList.add('disable');
        this.$refs.exam.classList.add('disable');
        this.$refs.certificate.classList.add('disable');
      }
      if(this.userData.payment) {
        this.$refs.overview.classList.remove('disable');
      }
    },
    makePayment() {
      this.$store.commit("togglePopup", {popup: "paymentPopup", status: true})
    }
  },
  watch: {
    accountPage() {
      this.checkCurrentPage();
    },
    userData() {
      this.checkDisable();
    }
  },
  mounted() {
    if (!this.getUserData.payment) {
      this.$store.commit('accountPage', "lecture");
    } else {
      this.$store.commit('accountPage', "overview");
    }
    this.checkCurrentPage();
    this.checkDisable();
  },
  // beforeDestroy() {
  //   this.$store.commit('accountPage', "overview");
  // }
}
</script>

<style lang="scss" scoped>
.accountWrapper {
  grid-row: 1/4;
  background-color: #202125;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 85%;
  height: 100%;
  width: 100%;
  border-radius: 0;
  opacity: 1;

  @media only screen and (min-width: 768px) {
    grid-row: 2/3;
    grid-template-columns: 24% 76%;
    grid-template-rows: 100%;
    width: 95%;
    border-radius: 2em;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 20% 80%;
    height: 98%;
  }
}
.accountBtnWrapper {
  grid-column: 1/2;
  grid-row: 1/2;
  border-right: none;
  border-bottom: 1pt solid #4A4643;
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 100%;

  @media only screen and (min-width: 768px) {
    border-bottom: none;
    border-right: 1pt solid #4A4643;
    grid-template-columns: 100%;
    grid-template-rows: 15% 10% 10% 10% 10% 45%;

    @media only screen and (max-height: 534px) {
      grid-template-rows: 13% 13% 13% 13% 13% 35%;
    }
  }
}
.accountBtn {
  width: 80%;
  height: 65%;
  display: grid;
  grid-template-columns: 32% 68%;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  border-radius: 1.5em;
  transition: 0.2s ease-in-out;

  &:hover {
    font-weight: 900;
  }

  @media only screen and (min-width: 768px) {
    height: 100%;
  }

  @media only screen and (min-width: 1440px) {
    width: 231px;
    height: 76px;
  }

  .accountIcon {
    grid-column: 1/3;
    grid-row: 1/2;
    width: 0.8em;
    justify-self: center;
    align-self: center;

    @media only screen and (min-width: 480px) {
      grid-column: 1/2;
    }

    @media only screen and (min-width: 768px) {
      width: 1.1em;
    }

    @media only screen and (min-width: 1024px) {
      width: 1.38em;
    }
  }

  .accountText {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: start;
    align-self: center;
    font-size: 8pt;
    color: white;
    display: none;

    @media only screen and (min-width: 480px) {
      display: block;
    }

    @media only screen and (min-width: 768px) {
      font-size: 10pt;
    }

    @media only screen and (min-width: 1280px) {
      font-size: 12pt;
    }
  }
}

.overview {
  grid-column: 1/2;
  grid-row: 1/2;

  @media only screen and (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

.lecture {
  grid-column: 2/3;
  grid-row: 1/2;

  @media only screen and (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}

.exam {
  grid-column: 3/4;
  grid-row: 1/2;

  @media only screen and (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 4/5;
  }
}

.certificate {
  grid-column: 4/5;
  grid-row: 1/2;

  @media only screen and (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 5/6;
  }
}

.logout {
  grid-column: 5/6;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 32% 68%;
  justify-self: center;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 6/7;
    max-height: 3em;
    align-self: end;
    margin-bottom: 1.5em;
  }
}

.accountContentWrapper {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;

  @media only screen and (min-width: 768px) {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
.noPaymentWarningWrapper {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  z-index: 50;
}
.noPaymentWarningBg {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 100%;
  width: 100%;
  background-color: #BFBFBF;
  opacity: 0.7;
  border-radius: 0 2em 2em 0;
  z-index: 0;
}
.noPaymentWarning {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 50%;
  width: 50%;
  align-self: center;
  justify-self: center;
  background-color: white;
  border-radius: 2em;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70% 30%;
  z-index: 20;
}
.noPaymentWarningText {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 65%;
  align-self: center;
  justify-self: center;
  font-size: 14pt;
}
.noPaymentWarningBtn {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 50%;
  height: 30%;
  display: grid;
  align-self: start;
  justify-self: center;
  border-width: 2pt;
  border-style: solid;
  border-radius: 2em;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.noPaymentWarningBtn:hover {
  background-color: black;
  color: white;
}
.noPaymentWarningBtn p {
  align-self: center;
  justify-self: center;
  font-size: 12pt;
  margin: 0;
}
.accountContent {
  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 10;
}
.accountCloseBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  height: 1.1em;
  margin: 5% 5% 0 0;
  cursor: pointer;
  z-index: 55;

  @media only screen and (min-width: 1024px) {
    height: 1.6em;
    margin: 3.5% 3.5% 0 0;
  }
}
.selected {
  // border-color: white;
  // border-style: solid;
  border: 2pt white solid;
  font-weight: 900;

  .accountIcon {
    grid-column: 1/2;
  }
  .accountText {
    display: block
  }
}
.disable {
  opacity: 0.5;
}
</style>
