<template>
  <div class="overviewWrapper">
    <div class="overviewName overviewContentWrapper">
      <p class="overviewContentTitle" v-if="language === 'zh'">名字</p>
      <p class="overviewContentTitle" v-if="language === 'en'">Name</p>
      <div class="overviewNameContent overviewContentBox">
        <p class="overviewContentText">{{ userData.englishName }}</p>
      </div>
    </div>
    <div class="overviewStudio overviewContentWrapper">
      <p class="overviewContentTitle" v-if="language === 'zh'">工作室</p>
      <p class="overviewContentTitle" v-if="language === 'en'">Studio Name</p>
      <div class="overviewStudioContent overviewContentBox">
        <p class="overviewContentText">{{ userData.studioName }}</p>
      </div>
    </div>
    <div class="overviewSignUpDate overviewContentWrapper">
      <p class="overviewContentTitle" v-if="language === 'zh'">註冊日期</p>
      <p class="overviewContentTitle" v-if="language === 'en'">Sign Up Date</p>
      <div class="overviewContentBox">
        <p class="overviewContentText">{{ userData.signUpDate }}</p>
      </div>
    </div>
    <div class="overviewExpiryDate overviewContentWrapper">
      <p class="overviewContentTitle" v-if="language === 'zh'">課程到期时间</p>
      <p class="overviewContentTitle" v-if="language === 'en'">Course Expiry Date</p>
      <div class="overviewContentBox">
        <p class="overviewContentText">{{ userData.courseExpiryDate }}</p>
      </div>
    </div>
    <div class="overviewStatusWrapper">
      <div class="overviewLectureProgress overviewContentWrapper2">
        <p class="overviewContentTitle2" v-if="language === 'zh'">課程進展</p>
        <p class="overviewContentTitle2" v-if="language === 'en'">Course Progress</p>
        <div class="overviewContentBox2">
          <p class="overviewStatusText">{{ userData.lectureProgress + "%" }}</p>
        </div>
      </div>
      <div class="overviewExamStatus overviewContentWrapper2">
        <p class="overviewContentTitle2" v-if="language === 'zh'">考試進展</p>
        <p class="overviewContentTitle2" v-if="language === 'en'">Exam Status</p>
        <div class="overviewContentBox2">
          <p class="examStatusText" v-if="language === 'zh' && !userData.examComplete">進展中</p>
          <p class="examStatusText" v-if="language === 'en' && !userData.examComplete">In Progress</p>
          <p class="examStatusText completed" v-if="language === 'zh' && userData.examComplete">完成</p>
          <p class="examStatusText completed" v-if="language === 'en' && userData.examComplete">Completed</p>
        </div>
      </div>
      <div class="overviewExamResult overviewContentWrapper2">
        <p class="overviewContentTitle2" v-if="language === 'zh'">考試成績</p>
        <p class="overviewContentTitle2" v-if="language === 'en'">Exam Result</p>
        <div class="overviewContentBox2">
          <p class="overviewStatusText"><span class="examResultNumber" ref="examResultNumber">{{ userData.examResult }}</span> | 20</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { db } from '@/components/firebase.js';
export default {
  name: 'Overview',
  data() {
    return {
      // userData: [],
      rawUserData: []
    }
  },
  computed: {
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    checkExamResult() {
      let userData = this.userData.examResult;
      let examResultNumber = this.$refs.examResultNumber;
      if(userData < 18) {
        examResultNumber.style.color = "#DB1F1F";
      }
    },
  },
  watch: {
    userData() {
      this.checkExamResult();
    }
  },
  mounted() {
    this.checkExamResult();
  }
}
</script>

<style lang="scss" scoped>
.overviewWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 7% 86% 7%;
  grid-template-rows: 2.2em 1fr 1fr 1fr 1fr auto 2.2em;
  gap: 1.5em 0;

  @media only screen and (min-width: 480px) {
    grid-template-columns: 7% 40% 6% 40% 7%;
    grid-template-rows: 14% 15% 5% 15% 6% 33% 12%;
    gap: 0;
  }

  @media only screen and (min-width: 768px) {
    grid-template-rows: 14% 15% 5% 15% 9% 29% 13%;
  }
}
.overviewName {
  grid-column: 2/3;
  grid-row: 2/3;

  @media only screen and (min-width: 480px) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}
.overviewStudio {
  grid-column: 2/3;
  grid-row: 3/4;

  @media only screen and (min-width: 480px) {
    grid-column: 4/5;
    grid-row: 2/3;
  }
}
.overviewSignUpDate {
  grid-column: 2/3;
  grid-row: 4/5;

  @media only screen and (min-width: 480px) {
    grid-column: 2/3;
    grid-row: 4/5;
  }
}
.overviewExpiryDate {
  grid-column: 2/3;
  grid-row: 5/6;

  @media only screen and (min-width: 480px) {
    grid-column: 4/5;
    grid-row: 4/5;
  }
}
.overviewContentWrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 35% 65%;
}
.overviewContentTitle {
  grid-column: 1/2;
  grid-row: 1/2;
  text-align: left;
  font-size: 8pt;
  font-weight: 500;
  margin: 0 0 0 1.2em;
  color: white;

  @media only screen and (min-width: 768px) {
    font-size: 10pt;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 12pt;
  }
}
.overviewContentText {
  text-align: left;
  font-size: 8pt;
  margin: 0 0 0 1.8em;
  align-self: center;

  @media only screen and (min-width: 768px) {
    font-size: 10pt;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 14pt;
  }
}
.overviewContentBox {
  grid-column: 1/2;
  grid-row: 2/3;
  background-color: #D3D3D3;
  width: 100%;
  border-radius: 1.7em;
  display: grid;
}
.overviewStatusWrapper {
  grid-column: 2/5;
  grid-row: 6/7;
  display: grid;
  grid-template-columns: 0 32% 32% 32% 0;
  grid-template-rows: 100%;

  @media only screen and (min-width: 480px) {
    grid-template-columns: 2% 32% 32% 32% 2%;
    grid-template-rows: 100%;
  }
}
.overviewLectureProgress {
  grid-column: 2/3;
  grid-row: 1/2;
}
.overviewExamStatus {
  grid-column: 3/4;
  grid-row: 1/2;
}
.overviewExamResult {
  grid-column: 4/5;
  grid-row: 1/2;
}
.overviewContentWrapper2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 28% 72%;
}
.overviewContentTitle2 {
  grid-column: 1/2;
  grid-row: 1/2;
  text-align: center;
  font-size: 8pt;
  font-weight: 500;
  align-self: center;
  color: white;

  @media only screen and (min-width: 480px) {
    font-size: 10pt;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 14pt;
  }
}
.overviewContentBox2 {
  grid-column: 1/2;
  grid-row: 2/3;
  background-color: #D3D3D3;
  width: 90%;
  height: 4.5em;
  max-height: 7.5em;
  border-radius: 2em;
  justify-self: center;
  display: grid;

  @media only screen and (min-width: 480px) {
    width: 88%;
    height: 100%;
  }

  @media only screen and (min-width: 1280px) {
    width: 230px;
    max-height: none ;
  }
}
.overviewStatusText {
  font-size: 18pt;
  font-weight: 500;
  margin: 0;
  align-self: center;
  justify-self: center;

  @media only screen and (min-width: 480px) {
    font-size: 20pt;
  }

  @media only screen and (min-width: 768px) {
    font-size: 24pt;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 34pt;
  }
}
.examStatusText {
  font-size: 10pt;
  font-weight: 500;
  margin: 0;
  align-self: center;
  justify-self: center;

  @media only screen and (min-width: 480px) {
    font-size: 14pt;
  }

  @media only screen and (min-width: 768px) {
    font-size: 16pt;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 20pt;
  }
}
</style>
