<template>
  <div class="paymentMethods">
    <div class="paymentTitle">
      <p v-if="paymentPopupPg === 'paymentMethods'">
        Choose your payment method
      </p>
      <p v-if="paymentPopupPg === 'cardPayment'">
        Card Payment
      </p>
      <p v-if="paymentPopupPg === 'paypalPayment'">
        PayPal Payment
      </p>
      <img src="@/assets/closeBtn.svg" class="paymentCloseBtn" v-if="paymentPopupPg === 'paymentMethods'" @click="paymentCloseBtn">
      <img src="@/assets/back_btn_black.svg" class="paymentCloseBtn" v-if="paymentPopupPg === 'cardPayment' || paymentPopupPg === 'paypalPayment'" @click="cardPaymentBackBtn">
    </div>
    <div class="paymentAmount">
      <div class="paymentBreakdown">
        <p class="item1 paymentItem">
          Course and Certificate:
        </p>
        <p class="amount1 amount">
          HKD {{ price }}
        </p>
        <p class="item2 paymentItem">
          Tax:
        </p>
        <p class="amount2 amount">
          HKD 0
        </p>
      </div>
      <div class="paymentTotal">
        <p class="paymentItem">
          Total:
        </p>
        <p class="amount">
          HKD {{ price }}
        </p>
      </div>
    </div>
    <div class="paymentMethodWrapper" v-if="paymentPopupPg === 'paymentMethods'">
      <div class="inputWrapper">
        <p class="inputTitle" v-if="language === 'zh'">折扣碼</p>
        <p class="inputTitle" v-if="language === 'en'">Promotion Code</p>
        <div class="warningTextWrapper">
          <p class="warningText" v-if="language === 'zh'" v-show="promoWarning">* 此折扣碼無效</p>
          <p class="warningText" v-if="language === 'en'" v-show="promoWarning">* This is not a valid code</p>
        </div>
        <input ref="lastNameInput" name="chineseName" type="text" v-model="promoCode">
        <button class="applyBtn" @click="applyPromo">
          APPLY
        </button>
      </div>
      <div class="cardPayment paymentOption" @click="launchPayment('cardPayment')"> 
        <img src="@/assets/card_payment3.svg">
      </div>
      <!-- <div class="paypal paymentOption" @click="launchPayment('paypalPayment')">
        <img class="paypalImage" src="@/assets/paypal.png" border="0" alt="PayPal Logo">
      </div> -->
    </div>
    <CardPayment class="cardPaymentForm" v-if="paymentPopupPg === 'cardPayment'" :approvedCode="approvedCode" @updatePrice="changePrice" />
    <PaypalPayment class="cardPaymentForm" v-if="paymentPopupPg === 'paypalPayment'" />
  </div>
</template>

<script>
import dbFunctions from '@/components/functions/database.js'
import CardPayment from './CardPayment.vue';
import PaypalPayment from './PaypalPayment.vue';
export default {
  name: 'PaymentMethods',
  components: {
    CardPayment,
    PaypalPayment
  },
  data() {
    return {
      price: '188',
      promoCode: '',
      approvedCode: '',
      promoWarning: false
    }
  },
  computed: {
    paymentPopup() {
      return this.$store.getters.paymentPopup;
    },
    paymentPopupPg() {
      return this.$store.getters.paymentPopupPg;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    cardPaymentBackBtn() {
      this.$store.commit("changePaymentPopup", "paymentMethods");
    },
    paymentCloseBtn() {
      this.$store.commit("togglePopup", {popup: "paymentPopup", status: false})
    },
    launchPayment(payment) {
      this.$store.commit("changePaymentPopup", payment)
    },
    async applyPromo() {
      const discount = await dbFunctions.checkPromoCode(this.promoCode);
      if(discount) {
        this.promoWarning = false;
        this.approvedCode = this.promoCode;
        this.changePrice(discount)
      } else {
        this.promoWarning = true;
      }
    },
    changePrice(discount) {
      const newPrice = 188 - 188*discount;
      this.price = newPrice.toFixed(2);
    }
  }
}
</script>

<style lang="scss" scoped>
.paymentMethods {
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
  background-color: white;
  border-radius: 1.2em;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 17% 30% 53%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
.paymentTitle {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 100%;
  display: grid;
  border-bottom: 2pt solid;

  p {
    grid-column: 1/2;
    grid-row: 1/2;
    width: 76%;
    justify-self: start;
    align-self: center;
    font-size: 14pt;
    font-weight: bold;
    margin: 0 0 0 2em;
    z-index: 0;

    @media only screen and (min-width: 768px) {
      font-size: 20pt;
    }
  }
}
.paymentCloseBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  align-self: center;
  height: 20px;
  margin: 0 6% 0 0;
  cursor: pointer;
  z-index: 20;

  @media only screen and (min-width: 768px) {
    height: 25px;
  }
}
.paymentAmount {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 85%;
  max-width: 500px;
  justify-self: center;
  align-self: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 65% 5% 30%;
}
.paymentBreakdown {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 90%;
  justify-self: center;
  align-self: center;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 50% 50%;
  background-color: #EAEAEA;
  border-radius: 1.5em;
}
.item1 {
  grid-column: 1/2;
  grid-row: 1/2;
}
.item2 {
  grid-column: 1/2;
  grid-row: 2/3;
}
.amount1 {
  grid-column: 2/3;
  grid-row: 1/2;
}
.amount2 {
  grid-column: 2/3;
  grid-row: 2/3;
}
.paymentItem {
  margin: 1em;
  align-self: center;
  font-weight: bold;
}
.amount {
  text-align: right;
  margin:1em;
  align-self: center;
}
.paymentTotal {
  grid-column: 1/2;
  grid-row: 3/4;
  width: 100%;
  height: 90%;
  justify-self: center;
  align-self: center;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 100%;
  background-color: #EAEAEA;
  border-radius: 1em;
}

.inputWrapper {
  grid-column: 1/2;
  // grid-row: 1/2;
  align-self: start;

  .inputTitle {
    margin: 0;
  }

  input {
    border-radius: 6px 6px 0 0;
    margin: 0;
    // border-radius: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }

  button {
    background: #0A2540;
    color: #ffffff;
    /* font-family: Arial, sans-serif; */
    text-align: center;
    border-radius: 0 0 6px 6px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;

    &:hover {
      filter: contrast(115%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .warningTextWrapper {
    height: 1em;
    display: grid;

    .warningText {
      font-size: 8pt;
      color: red;
      text-align: left;
      margin: 0 0 0 1em;
      align-self: center;
    }
    .warningText2 {
      font-size: 6pt;
      color: white;
      text-align: left;
      margin: 0 0 0 1em;
      align-self: center;

      @media only screen and (min-width: 768px) {
        font-size: 8pt;
      }
    }
  }
}
.paymentMethodWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  height: 90%;
  width: 85%;
  max-width: 500px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: max-content max-content;
  justify-self: center;
  align-self: start;
  margin-bottom: 2%;
  gap: 10%;
}
.paymentOption {
  height: 7.5em;
  max-height: 120px;
  width: 100%;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  display: grid;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: black;
  }
}
.cardPayment {
  grid-column: 1/2;
  // grid-row: 1/2;
  border-color: black;
  border-style: solid;
  border-width: 2pt;
  border-radius: 1.5em;
}
.cardPayment img {
  height: 40px;
  justify-self: center;
  align-self: center;
}
.paypal {
  grid-column: 1/2;
  grid-row: 2/3;
  border-color: black;
  border-style: solid;
  border-width: 2pt;
  border-radius: 1.5em;
}
.paypalImage {
  height: 40px;
  justify-self: center;
  align-self: center;
}
.cardPaymentForm {
  grid-column: 1/2;
  grid-row: 3/4;
  height: 90%;
  width: 90%;
  max-width: 500px;
  justify-self: center;
  align-self: center;
}
</style>
