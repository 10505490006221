<template>
  <div class="menuIconWrapper">
    <svg>
      <defs>
        <filter id="gooeyness">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2.2" result="blur" />
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10" result="gooeyness" />
          <feComposite in="SourceGraphic" in2="gooeyness" operator="atop" />
        </filter>
      </defs>
    </svg>
    <div class="plates">
      <div class="plate plate4" ref="menuIcon" @click="toggleMenu">
        <svg class="burger" version="1.1" height="50" width="50" viewBox="0 0 100 100">
          <path class="line line1" d="M 50,37 H 31" />
          <path class="line line2" d="M 50,37 H 69" />
          <path class="line line3" d="M 50,50 H 31" />
          <path class="line line4" d="M 50,50 H 69" />
          <path class="line line5" d="M 50,63 H 31" />
          <path class="line line6" d="M 50,63 H 69" />
        </svg>
        <svg class="x" version="1.1" height="100" width="100" viewBox="0 0 100 100">
          <path class="line" d="M 36,34 L 64,63" />
          <path class="line" d="M 64,34 L 36,63" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuIcon',
  computed: {
    menuPopup() {
      return this.$store.getters.menuPopup;
    }
  },
  methods: {
    toggleMenu() {
      this.$refs.menuIcon.classList.toggle('active')
      if(this.$refs.menuIcon.classList.contains('active')) {
        this.$store.commit("togglePopup", {popup: 'menuPopup', status: true})
      } else {
        this.$store.commit("togglePopup", {popup: 'menuPopup', status: false})
      }
    }
  },
  watch: {
    menuPopup() {
      if(!this.menuPopup) {
        this.$refs.menuIcon.classList.remove('active')
      }
    }
  }
}
</script>

<style scoped>
.menuIconWrapper {
  margin: 0;
}
svg {
  height: 5em;
  position: absolute;
  width: 5em;
}
.plates {
  display: flex;
  flex-wrap: wrap;
}

.plate {
  height: 80px;
  width: 80px;
  cursor: pointer;
}
.burger {
  filter: url(#gooeyness);
}
.x {
  transform: scale(0);
  transition: transform 400ms;
}
.line {
  fill: none;
  stroke: white;
  stroke-width: 5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform-origin: 50%;
  transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, transform 500ms 200ms;
}
.x .line {
  stroke-width: 5px;
}

/* Die vierte teller */
.plate4 .x {
  transition: transform 400ms;
}
.plate4 .line {
  transform-origin: 50%;
  transition: transform 400ms 100ms;
}
.active.plate4 .line {
  transition: transform 400ms;
}
.active.plate4 .line1 {
  transform: translateX(16px) translateY(-4px) rotate(-45deg) scale(.7);
}
.active.plate4 .line2 {
  transform: translateX(-16px) translateY(-4px) rotate(45deg) scale(.7);
}
.active.plate4 .line3 {
  transform: translateY(0px) rotate(45deg) scale(.7);
}
.active.plate4 .line4 {
  transform: translateY(0px) rotate(-45deg) scale(.7);
}
.active.plate4 .line5 {
  transform: translateX(19px) translateY(4px) rotate(45deg) scale(.7);
}
.active.plate4 .line6 {
  transform: translateX(-19px) translateY(4px) rotate(-45deg) scale(.7);
}
.active.plate4 .x {
  transition: transform 400ms 100ms;
  transform: scale(1);
}
</style>