<template>
  <div class="paymentSuccessPopup">
    <p class="paymentSuccessText" v-if="paymentStatus ==='success'">Payment Success</p>
    <p class="paymentSuccessText" v-if="paymentStatus ==='fail'">Payment Fail</p>
    <div class="tickWrapper" v-if="paymentStatus ==='success'">
      <svg class="tick">
        <path id="check" d="M20,50 l22,35 l90,-65" />
      </svg>
    </div>
    <div class="tickWrapper" v-if="paymentStatus ==='fail'">
      <img src="@/assets/fail_icon.svg">
    </div>
    <div class="paymentSuccessBtn" v-if="paymentStatus ==='success'" @click="goToAccount">
      <p>Go to My Account</p>
    </div>
    <div class="paymentSuccessBtn" v-if="paymentStatus ==='fail'" @click="backToPaymentMethods">
      <p>BACK</p>
    </div>
  </div>
</template>

<script>
// import PaymentMethods from '@/components/PaymentMethods.vue';
// import CardPayment from '@/components/CardPayment.vue';
export default {
  name: 'PaymentStatus',
  // components: {
  //   PaymentMethods,
  //   CardPayment
  // },
  computed: {
    paymentPopupPg() {
      return this.$store.getters.paymentPopupPg;
    },
    paymentStatus() {
      return this.$store.getters.getPaymentStatus;
    }
  },
  methods: {
    paymentCloseBtn() {
      this.$store.commit("paymentPopup", false)
    },
    goToAccount() {
      this.$store.commit("changePaymentPopup", "paymentMethods");
      this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
    },
    backToPaymentMethods() {
      this.$store.commit("changePaymentPopup", "paymentMethods");
    }
  }
}
</script>

<style scoped>
.paymentSuccessPopup {
  height: 700px;
  width: 890px;
  justify-self: center;
  align-self: center;
  background-color: white;
  border-radius: 1.2em;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 27% 43% 30%;
}
.paymentSuccessText {
  grid-column: 1/2;
  grid-row: 1/2;
  font-size: 24pt;
  justify-self: center;
  align-self: end;
}
.tickWrapper {
  grid-column: 1/2;
  grid-row: 2/3;
  display: grid;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background-color: #3BC146;
  justify-self: center;
  align-self: center;
}
.tick {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 140px;
  height: 140px;
  justify-self: center;
  align-self: center;
  margin: 40px 0 0 5px;
}
#check {
  fill: none;
  stroke: white;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-dasharray: 140;
  stroke-dashoffset: 140;
  animation: draw 0.5s linear forwards;
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
.paymentSuccessBtn {
  grid-column: 1/2;
  grid-row: 3/4;
  height: 60px;
  width: 70%;
  justify-self: center;
  align-self: center;
  border-radius: 2em;
  border-color: black;
  border-style: solid;
  border-width: 2pt;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.paymentSuccessBtn:hover {
  background-color: black;
  color: white;
}
.paymentSuccessBtn p {
  font-size: 14pt;
  justify-self: center;
  align-self: center;
  margin: 0;
}
@media only screen and (max-height: 900px)  and (orientation: landscape) {
  .paymentSuccessPopup {
    height: 540px;
    width: 731px;
    grid-template-rows: 27% 42% 31%;
  }
  .paymentSuccessText {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: 20pt;
    justify-self: center;
    align-self: end;
  }
  .tickWrapper {
    height: 180px;
    width: 180px;
  }
  .tick {
    grid-column: 1/2;
    grid-row: 1/2;
    width: 150px;
    height: 150px;
    justify-self: center;
    align-self: center;
    margin: 40px 0 0 10px;
  }
  .paymentSuccessBtn {
    height: 50px;
    width: 60%;
  }
  .paymentSuccessBtn p {
    font-size: 13pt;
  }
}
</style>
