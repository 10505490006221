<template>
  <div class="paymentPopup">
    <!-- <img src="@/assets/closeBtn.svg" class="paymentCloseBtn" @click="paymentCloseBtn"> -->
    <PaymentMethods class="paymentMethods" v-if="paymentPopupPg !== 'paymentStatus'"  />
    <PaymentStatus class="paymentMethods" v-if="paymentPopupPg === 'paymentStatus'" />
    <!-- <CardPayment class="paymentMethods" v-if="paymentPopupPg === 'cardPayment'" /> -->
  </div>
</template>

<script>
import PaymentMethods from './PaymentMethods.vue';
import PaymentStatus from './PaymentStatus.vue';
// import CardPayment from '@/components/CardPayment.vue';
export default {
  name: 'PaymentPopup',
  components: {
    PaymentMethods,
    PaymentStatus
    // CardPayment
  },
  computed: {
    paymentPopupPg() {
      return this.$store.getters.paymentPopupPg;
    }
  },
  methods: {
    paymentCloseBtn() {
      this.$store.commit("togglePopup", {popup: "paymentPopup", status: false})
    },
    launchCardPayment() {
      this.$store.commit("changePaymentPopup", "cardPayment")
    }
  },
  // beforeDestroy() {
  //   this.paymentCloseBtn();
  // }
}
</script>

<style lang="scss" scoped>
.paymentPopup {
  grid-column: 1/2;
  grid-row: 1/4;
  height: 100%;
  width: 100%;
  max-width: 890px;
  justify-self: center;
  align-self: center;
  background-color: white;
  border-radius: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;

  @media only screen and (min-width: 480px) {
    height: 90%;
    width: 95%;
    border-radius: 1.2em;
  }

  @media only screen and (min-width: 768px) {
    grid-row: 2/3;
  }

  @media only screen and (min-width: 935px) {
    height: 85%;
  }
}
.paymentMethods {
  grid-column: 1/2;
  grid-row: 1/2;
}
.paymentCloseBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  height: 25px;
  margin: 6% 6% 0 0;
  cursor: pointer;
  z-index: 20;
}
</style>
