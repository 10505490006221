<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: 'App',
  methods: {
    logout() {
      firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("Logged Out");
      })
      .catch(err => {
        console.log(err);
        alert(err);
      });
    }
  },
  beforeDestroy() {
    this.logout();
  }
}
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #000000;
  /* background-color: #202125; */
  height: 100vh;
  width: 100vw;
}
</style>
