<template>
  <div class="home">
    <video src="@/assets/bg_video_colour_LR.mp4" autoplay muted loop playsinline poster="@/assets/bg_video_snapshot.png" class="videoBg" />
    <div class="popupBg" v-show="popup"></div>
    <Menu class="menuWrapper" />
    <HomeCallToAction class="homeCallToAction"  v-show="popup === false" />
    <About class="aboutPopup" v-if="aboutPopup" />
    <CertifiedList class="aboutPopup" v-if="certListPopup" />
    <LoginPopup class="loginPopup" v-if="loginPopup" />
    <PasswordResetPopup class="loginPopup" v-if="passwordResetPopup" />
    <Account class="accountPopup" v-if="accountPopup" />
    <Course class="contactPopup" v-if="coursePopup" />
    <SignUpForm class="signUpFormPopup" v-if="signUpFormPopup" />
    <PaymentPopup class="paymentPopup" v-if="paymentPopup" />
    <ContactPopup class="contactPopup" v-if="contactPopup" />
    <Faq class="contactPopup" v-if="faqPopup" />
    <CoursePurchaseForm class="signUpFormPopup" v-if="coursePurchaseForm" />
    <TermsAndConditions class="aboutPopup" v-if="termsAndConditionsPopup" />
    <div class="socialIconWrapper" v-show="!this.menuPopup">
      <a href="https://www.instagram.com/bmtahk/" target="_blank" class="socialIcon instagram"><img src="@/assets/instagram_icon.svg" class="socialImg"></a>
      <a href="https://www.facebook.com/bmtahk?locale=zh_TW" target="_blank" class="socialIcon facebook"><img src="@/assets/facebook_icon.svg" class="socialImg"></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from '@/components/menu/Menu.vue';
import HomeCallToAction from '@/components/HomeCallToAction.vue';
import About from '@/components/About.vue';
import CertifiedList from '@/components/CertifiedList.vue';
import LoginPopup from '@/components/LoginPopup.vue';
import PasswordResetPopup from '@/components/PasswordResetPopup.vue';
import Account from '@/components/accountPopup/Account.vue';
import Course from '@/components/Course.vue';
import SignUpForm from '@/components/SignUpForm.vue';
import PaymentPopup from '@/components/payment/PaymentPopup.vue';
import ContactPopup from '@/components/ContactPopup.vue';
import Faq from '@/components/Faq.vue';
import CoursePurchaseForm from './CoursePurchaseForm.vue';
import TermsAndConditions from './TermsAndConditions.vue';
export default {
  name: 'Home',
  components: {
    Menu,
    HomeCallToAction,
    About,
    CertifiedList,
    LoginPopup,
    PasswordResetPopup,
    Account,
    Course,
    SignUpForm,
    PaymentPopup,
    ContactPopup,
    Faq,
    CoursePurchaseForm,
    TermsAndConditions
  },
  computed: {
    ...mapGetters([
      'popup',
      'homeCallToAction',
      'aboutPopup',
      'certListPopup',
      'loginPopup',
      'passwordResetPopup',
      'accountPopup',
      'coursePopup',
      'signUpFormPopup',
      'termsAndConditionsPopup',
      'paymentPopup',
      'contactPopup',
      'faqPopup',
      'coursePurchaseForm',
      'menuPopup'
    ])
  },
  methods: {
    
  }
}

</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 9% 83% 8%;
  background-color: #202125;
}
.videoBg {
  grid-column: 1/2;
  grid-row: 1/4;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  object-fit: cover;
  z-index: 0;
}
.popupBg {
  grid-column: 1/2;
  grid-row: 1/4;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  object-fit: cover;
  z-index: 2;
  background-color: #BFBFBF;
  opacity: 0.4;
}
.menuWrapper {
  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 10;
}
.centreContent {
  grid-column: 1/2;
  grid-row: 2/4;
  /* height: 50%; */
  align-self: center;
  justify-self: center;
  z-index: 10;
}
.homeCallToAction {
  grid-column: 1/2;
  grid-row: 2/3;
  /* height: 50%; */
  align-self: center;
  justify-self: center;
  z-index: 0;
  transition: 0.3s ease-in-out;
  margin: 0 2em;

  @media only screen and (min-width: 768px) {
    margin: 0;
  }
}
.aboutScrollWrapper {
  grid-column: 1/2;
  overflow: auto;
}
.aboutWrapper {
  grid-column: 1/2;
  /* grid-row: 2/4; */
  width: 100%;
  /* height: 100%; */
  z-index: 20;
  justify-self: center;
  align-self: center;
  display: grid;
  /* overflow: auto; */
  margin: 0;
}
/* .aboutPopup {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 80%;
  height: 100%;
  border-radius: 1.5em;
  justify-self: center;
  align-self: center;
  position: relative;
} */
.aboutPopup {
  grid-column: 1/2;
  z-index: 20;
}
.loginPopup {
  grid-column: 1/2;
  grid-row: 1/4;
  height: 480px;
  width: 100%;
  justify-self: center;
  align-self: center;
  border-radius: 2em;
  z-index: 20;

  @media only screen and (min-width: 768px) {
    height: 518px;
    width: 673px;
  }
}
.accountPopup {
  grid-column: 1/2;
  justify-self: center;
  align-self: center;
  z-index: 20;
}
.signUpFormPopup {
  grid-column: 1/2;
  z-index: 20;
}
.paymentPopup {
  z-index: 20;
}
.socialIconWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  display: grid;
  height: 4.5em;
  align-self: end;
  grid-template-columns: auto max-content max-content auto;
  grid-template-rows: 100%;
  z-index: 10;
  gap: 2em;

  // @media only screen and (min-width: 768px) {
  //   gap: 0;
  // }
}

.socialIcon {
  height: fit-content;
  width: fit-content;
  justify-self: center;
  align-self: center;
  display: grid;

  img {
    height: 1em;

    @media only screen and (min-width: 768px) {
      height: 1em;
    }

    @media only screen and (min-width: 1024px) {
      height: 1.4em;
    }

    @media only screen and (min-width: 1280px) {
      height: 1.8em;
    }
  }
}

.instagram {
  grid-column: 2/3;
  grid-row: 1/2;
}
.facebook {
  grid-column: 3/4;
  grid-row: 1/2;
}
.mewe {
  grid-column: 4/5;
  grid-row: 1/2;
}

.socialImg {
  justify-self: center;
  align-self: center;
}
</style>
