<template>
<div class="lectureWrapper">
  <div class="lectureBtnStatus">
    <img src="@/assets/back_btn.svg" class="lectureWrapperBackBtn" @click="lectureWrapperBackBtn">
    <div class="lectureProgressWrapper">
      <div class="lectureProgressBarWrapper">
        <span class="lectureProgressBar" :style="{width: lectureProgress}"></span>
      </div>
      <p class="lectureProgress">
        {{ lectureProgress }}
      </p>
    </div>
    <div class="langBtn" @click="languageChange">
      <p v-if="language === 'en'">EN</p>
      <p v-if="language === 'zh'">中</p>
    </div>
  </div>
  <img src="@/assets/lecture_next_btn.svg" class="lectureNextBtn lectureBtn" @click="lectureNextBtn">
  <img src="@/assets/lecture_back_btn.svg" class="lectureBackBtn lectureBtn" @click="lectureBackBtn" v-show="lecturePg !==0">
  <div class="lectureContentWrapper" ref="lectureContentWrapper">
    <component
      :is="currentPg.element"
      :src="currentPg.src"
      :autoplay="currentPg.isVideo ? true : false"
      :controls="currentPg.isVideo ? true : false"
      class="coursePg"
    >
      <slot />
    </component>
    <a href="https://www.chp.gov.hk/en/healthtopics/content/460/19728.html" class="videoRefLink" v-show="lecturePg === 35 || lecturePg === 36" target="_blank" rel="noopener noreferrer">
      <span v-if="language === 'zh'">參考: 衛⽣防護中⼼-正確洗⼿⽅法</span>
      <span v-if="language === 'en'">Reference: Centre for Health Protection, Department of Health HKSAR</span>
    </a>
    <div class="refLinkWrapper" v-if="lecturePg !== 22 && lecturePg !== 23 && lecturePg !== 35 && lecturePg !== 36">
      <a href="https://www.27802211.com/tc/booking_hotline.html" v-if="lecturePg === 17 && language === 'zh'" class="refLink link0zh" target="_blank" rel="noopener noreferrer"></a>
      <a href="https://www.27802211.com/en/booking_hotline.html" v-if="lecturePg === 17 && language === 'en'" class="refLink link0" target="_blank" rel="noopener noreferrer"></a>
      <a href="https://www.chp.gov.hk/tc/static/27196.html" v-if="lecturePg === 32 && language === 'zh'" class="refLink link1zh" target="_blank" rel="noopener noreferrer"></a>
      <a href="https://www.chp.gov.hk/en/static/27196.html" v-if="lecturePg === 32 && language === 'en'" class="refLink link1" target="_blank" rel="noopener noreferrer"></a>
      <a href="https://www.epd.gov.hk/epd/clinicalwaste/file/doc06_tc.pdf" v-if="lecturePg === 38 && language === 'zh'" class="refLink link2zh" target="_blank" rel="noopener noreferrer"></a>
      <a href="https://www.epd.gov.hk/epd/clinicalwaste/file/doc06_en.pdf" v-if="lecturePg === 38 && language === 'en'" class="refLink link2" target="_blank" rel="noopener noreferrer"></a>
    </div>
  </div>
</div>
</template>

<script>
import { db } from '@/components/firebase.js';
import courseData from './data/coursePg.json';

export default {
  name: 'LectureWrapper',
  data() {
    return {
      lectureProgress: "0%",
      currentPg: {
        element: "",
        src: "",
        isVideo: false
      }
    }
  },
  computed: {
    lecturePg() {
      return this.$store.getters.getLecturePg;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
  },
  methods: {
    lectureWrapperBackBtn() {
      this.$router.push('/');
      this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
      this.$store.commit('accountPage', "coursePurchase");
    },
    lectureNextBtn() {
      if(this.lecturePg < 46) {
        this.$store.commit('changeLecturePg', this.lecturePg+1);
      } else {
        this.lectureWrapperBackBtn()
      }
    },
    lectureBackBtn() {
      if(this.lecturePg > 0) {
        this.$store.commit('changeLecturePg', this.lecturePg-1);
      }
    },
    changePg() {
      courseData.forEach(data => {
        if (data.pg === this.lecturePg) {
          this.currentPg.element = data.type;
          if (data.type === "video") {
            this.currentPg.isVideo = true;
          } else {
            this.currentPg.isVideo = false;
          }
          if (this.language === "en") {
            this.currentPg.src = data.src.en;
          } else {
            this.currentPg.src = data.src.zh;
          }
        }
      })
    },
    lectureProgressCal() {
      let percentageCal = (this.lecturePg/46)*100;
      this.lectureProgress = percentageCal.toFixed(0)+"%";
      if(this.userData.lectureProgress < percentageCal) {
        db.collection("user_data").doc(this.userEmail)
        .update({
          "lectureProgress": percentageCal.toFixed(0)
        })
        .then(() => {
          console.log("Lecture progress updated");
        })
        .catch((err) => {
          console.log("Lecture progress updated: " + err);
        })
      }
    },
    languageChange() {
      if (this.language === "en") {
        this.$store.commit("changeLanguage", "zh");
      }
      else {
        this.$store.commit("changeLanguage", "en");
      }
    }
  },
  watch: {
    lecturePg() {
      this.lectureProgressCal();
      this.changePg();
    },
    language() {
      this.changePg();
    }
  },
  mounted() {
    this.lectureProgressCal();
    this.changePg();
  }
}
</script>

<style lang="scss" scoped>
p {
  color: black;
}
::-webkit-scrollbar {
    display: none;
}
.lectureWrapper {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 8% 84% 8%;
  grid-template-rows: 10% 85% 5%;
  overflow: hidden;
  background-color: #202125;
}
.lectureBtnStatus {
  grid-column: 1/4;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 15% 9% 54% 7% 15%;
  grid-template-rows: 100%;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 8% 27% 32% 25% 8%;
  }
}
.lectureWrapperBackBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  height: 1.2em;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    height: 1.5em;
  }
}

.lectureProgressWrapper {
  grid-column: 3/4;
  grid-row: 1/2;
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
  display: grid;
  grid-template-columns: 77% 23%;
  grid-template-rows: 100%;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 80% 20%;
  }

  .lectureProgressBarWrapper {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 20%;
    width: 100%;
    /* justify-self: center; */
    align-self: center;
    border-radius: 0.8em;
    border: 0.15em solid white;

    .lectureProgressBar {
      background-color: white;
      display: block;
      /* width: 50%; */
      height: 100%;
      border-radius: 0.5em;
      z-index: 0;
      transition: 0.3s ease-in-out;
    }
  }

  .lectureProgress {
    grid-column: 2/3;
    grid-row: 1/2;
    color: white;
    justify-self: center;
    align-self: center;
    font-size: 12pt;
  }
}

.langBtn {
  grid-column: 5/6;
  grid-row: 1/2;
  height: 1.5em;
  width: 1.5em;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  border-style: solid;
  border-color: white;
  border-width: 2pt;
  border-radius: 0.4em;
  display: grid;

  @media only screen and (min-width: 768px) {
    height: 2.1em;
    width: 2.1em;
  }

  p {
    color: white;
    font-size: 10pt;
    font-weight: 500;
    text-align: center;
    justify-self: center;
    align-self: center;
    margin: 0;

    @media only screen and (min-width: 768px) {
      font-size: 14pt;
    }
  }
}

.lectureBtn {
  height: 30px;
  justify-self: center;
  align-self: center;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    height: 48px;
  }
}

.lectureNextBtn {
  grid-column: 3/4;
  grid-row: 2/3;
}
.lectureBackBtn {
  grid-column: 1/2;
  grid-row: 2/3;
}
.lectureScrollWrapper {
  grid-column: 2/3;
  grid-row: 2/3;
  height: 100%;
  width: 100%;
  /* border-radius: 1.5em; */
  overflow: auto;
}
.lectureContentWrapper {
  grid-column: 2/3;
  grid-row: 2/3;
  width: 100%;
  height: fit-content;
  z-index: 20;
  justify-self: center;
  align-self: center;
  display: grid;
  justify-content: center;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    align-self: center;
  }

  .coursePg {
    grid-column: 1/2;
    grid-row: 1/2;
    max-height: 100%;
    max-width: 100%;
    width: fit-content;
    align-self: center;
  }

  .videoRefLink {
    position: absolute;
    align-self: start;
    justify-self: center;
    color: #fff;
    font-size: 6pt;
    z-index: 40;
    margin-top: 1em;

    @media only screen and (min-width: 480px) {
      font-size: 8pt;
    }

    @media only screen and (min-width: 768px) {
      font-size: 12pt;
    }
  }

  .refLinkWrapper {
    grid-row: 1/2;
    grid-column: 1/2;
    z-index: 30;
    overflow: hidden;

    .refLink {
      height: 0.6em;
      margin: 0;
      position: relative;
      display: block;

      @media only screen and (min-width: 768px) {
        height: 1.5em;
      }

      @media only screen and (min-width: 900px) {
        height: 1.8em;
      }
    }

    .link0 {
      width: 34%;
      top: 74%;
      left: 52%;
    }

    .link0zh {
      width: 28%;
      top: 80%;
      left: 51%;
    }

    .link1 {
      width: 62%;
      top: 58%;
      left: 6%;
    }

    .link1zh {
      width: 42%;
      top: 61.5%;
      left: 9%;
    }

    .link2 {
      width: 47%;
      top: 71%;
      left: 3%;
    }

    .link2zh {
      width: 41%;
      top: 38%;
      left: 45%;
    }
  }
}
// .lectureContent {
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   display: grid;
//   grid-template-columns: 8% 84% 8%;
//   /* grid-template-rows: 15% minmax(min-content, max-content) 10%; */
//   background-color: white;
//   border-radius: 1.5em;
//   justify-self: center;
//   align-self: center;
// }
.lectureButtonWrapper {
  grid-column: 2/3;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  padding: 4% 0 5% 0;
}
.lectureSignUpBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  height: 46px;
  width: 232px;
  background-color: #F66F51;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.lectureSignUpBtn:hover {
  background-color: black;
}
.lectureCancelBtn {
  grid-column: 2/3;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  height: 46px;
  width: 232px;
  background-color: #F66F51;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.lectureCancelBtn:hover {
  background-color: black;
}
.lectureBtnText {
  color: white;
  align-self: center;
  font-size: 12pt;
  margin: 0;
  align-self: center;
  justify-self: center;
}
</style>
