<template>
<!-- <div class="aboutScrollWrapper"> -->
  <div class="popupWrapper">
    <div class="popupContent">
      <h1 class="popupTitle" v-if="language === 'zh'">培訓課程</h1>
      <h1 class="popupTitle" v-if="language === 'en'">Course</h1>
      <img src="@/assets/close_btn_white.svg" class="closeBtn" @click="closePopup">
      <div class="popupTextWrapper">
        <h2 v-if="language === 'en'">Bloodborne Pathogen Online Course & Certification</h2>
        <h2 v-if="language === 'zh'">「ABBP 亞洲人體藝術衛生管理」網上專業證書課程</h2>
        <p v-if="language === 'en'">
          <strong>ABBP</strong> offers the first professional online bloodborne pathogen training course specifically designed for body modification practitioners working in Asia including, but not limited to, tattoo artists, piercers, semi-permanent and permanent makeup specialists.
        </p>
        <p v-if="language === 'en'">
          The <strong>Asia Bloodborne Pathogen Certificate</strong> is granted upon the successful completion of the <strong>ABBP</strong> training course, valid for 1 year.
        </p>
        <p v-if="language === 'en'">
          <strong>ABBP's post-training in-person consultation and inspection</strong> provides artists the opportunity to perfect their hygiene practice after taking the course. During the consultation, <strong>ABBP</strong>'s highly-trained staff will inspect the workspace to ensure all safety practices are up to standard, and answer any enquiries artists may still have regarding the actual set-up.
        </p>
        <p v-if="language === 'en'">
          <strong>ABBP</strong>’s in-person consultation is currently available in Hong Kong. If you are based outside Hong Kong and are interested in our service, email <a href="mailto:info@asiabbp.com">info@asiabb.com</a>. 
        </p>
        <p v-if="language === 'en'">
          To maintain a consistent calibre across all registered artists, ABBP reserves the right to revoke the certification should there be any continuous reports or observations of substandard hygiene practice.
        </p>
        <p v-if="language === 'en'">
          As body modification continues to grow exponentially in Asia, we hope that by making both online and offline resources accessible to both the artists and the wider public, all individuals can enjoy a safe experience as they seek to express themselves in body art.
        </p>
        <p v-if="language === 'zh'">
          <strong>ABBP</strong> 提供首個專為亞洲地區而設、針對有關「血源性病原體」方面的網上專業證書課程。凡從事人體藝術行業，包括職業紋身師、穿環師、紋繡師等等，均適合報讀課程。
        </p>
        <p v-if="language === 'zh'">
          完成 <strong>ABBP</strong> 證書課程及通過考試後，<strong>ABBP</strong> 會向從業員頒發「亞洲人體藝術衛生管理培訓證書」（Asia Bloodborne Pathogen Certificate），證書有效期為一年。
        </p>
        <p v-if="language === 'zh'">
          除此之外，<strong>ABBP</strong> 亦向學員提供培訓後的諮詢及檢查服務。<strong>ABBP</strong> 的專業團隊會親自到訪從業員的工作室，就實際工作環境作出衛生建議，確保工作場所符合安全及衛生標準。若從業員就任何衛生守則及工作環境的設置有任何疑問，歡迎於諮詢期間向 <strong>ABBP</strong> 團隊查詢。
        </p>
        <p v-if="language === 'zh'">
          <strong>ABBP</strong> 的上門諮詢及檢查服務目前只限於香港地區。若果你身處海外，並對我們的服務感到興趣，歡迎透過電郵與我們聯絡：<a href="mailto:info@asiabbp.com">info@asiabb.com</a>。
        </p>
        <p v-if="language === 'zh'">
          若發現工作場所衛生標準欠佳，ABBP 將保留撤銷從業員的證書的權利，以確保經 <strong>ABBP</strong> 註冊的從業員質素得到一致的保證。
        </p>
        <p v-if="language === 'zh'">
          隨著人體藝術在亞洲變得愈來愈普及，<strong>ABBP</strong> 希望透過提供專業證書課程及線上線下資源，提升進行紋身、穿環、紋繡等活動場所的衛生標準，讓公眾能夠安心地透過人體藝術表達自己。
        </p>
        <h2 v-if="language === 'en'">The Course</h2>
        <h2 v-if="language === 'zh'">關於 「ABBP 亞洲人體藝術衛生管理」證書課程</h2>
        <table class="popupDesktopTable" v-if="language === 'en'">
          <thead>
            <th>
              LEARN & STUDY
            </th>
            <th>
              TAKE THE EXAM
            </th>
            <th>
              GET YOUR CERTIFICATE & SEAL
            </th>
          </thead>
          <tr>
            <td>
              <ul>
                <li>
                  Online 24/7 self-paced Bloodborne Pathogen Training
                </li>
                <li>
                  Specifically designed for body modification artists
                </li>
                <li>
                  Comprehensive and easy to understand
                </li>
                <li>
                  Course materials and exam available in English and Chinese
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Up to 60 days to take and review the training before the exam
                </li>
                <li>
                  Online exam
                </li>
                <li>
                  Free retakes. You are guaranteed a certificate.
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Receive the digital version of your ABBP certificate immediately upon completion. Hardcopy to be delivered to your registered address shortly.
                </li>
                <li>
                  Certificate valid for 1 year
                </li>
                <li>
                  Receive your ABBP Quality Seal 
                </li>
                <li>
                  Endorsed by the Hong Kong Body Modification and Tattoo Artists Association
                </li>
              </ul>
            </td>
          </tr>
        </table>
        <table class="popupDesktopTable" v-if="language === 'zh'">
          <thead>
            <th>
              自主學習
            </th>
            <th>
              參與考試
            </th>
            <th>
              取得證書及認證
            </th>
          </thead>
          <tr>
            <td>
              <ul>
                <li>
                  網上培訓課程，隨時隨地自由學習
                </li>
                <li>
                  課程為人體藝術行業（如紋身、穿環、紋繡等）度身訂造
                </li>
                <li>
                  課程內容清晰全面，容易理解
                </li>
                <li>
                  學習教材和考試內容均設有英文或中文版
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  以60日為期限，須於課程開始起計，兩個月內內完成考試
                </li>
                <li>
                  考試於網上進行
                </li>
                <li>
                  60日內免費無限次補考，保證取得證書
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  通過考試後，立即取得電子版 ABBP 證書
                </li>
                <li>
                  實體版證書及「ABBP衛生認證」將稍後郵寄至已登記地址
                </li>
                <li>
                  證書有效期為一年
                </li>
                <li>
                  課程及證書由香港紋身師及穿環師協會全力協辨
                </li>
              </ul>
            </td>
          </tr>
        </table>

        <table class="popupMobileTable" v-if="language === 'zh'">
          <th>
            自主學習
          </th>
          <tr>
            <td>
              <ul>
                <li>
                  網上培訓課程，隨時隨地自由學習
                </li>
                <li>
                  課程為人體藝術行業（如紋身、穿環、紋繡等）度身訂造
                </li>
                <li>
                  課程內容清晰全面，容易理解
                </li>
                <li>
                  學習教材和考試內容均設有英文或中文版
                </li>
              </ul>
            </td>
          </tr>
          <th>
            參與考試
          </th>
          <tr>
            <td>
              <ul>
                <li>
                  以60日為期限，須於課程開始起計，兩個月內內完成考試
                </li>
                <li>
                  考試於網上進行
                </li>
                <li>
                  60日內免費無限次補考，保證取得證書
                </li>
              </ul>
            </td>
          </tr>
          <th>
            取得證書及認證
          </th>
          <tr>
            <td>
              <ul>
                <li>
                  通過考試後，立即取得電子版 ABBP 證書
                </li>
                <li>
                  實體版證書及「ABBP衛生認證」將稍後郵寄至已登記地址
                </li>
                <li>
                  證書有效期為一年
                </li>
                <li>
                  課程及證書由香港紋身師及穿環師協會全力協辨
                </li>
              </ul>
            </td>
          </tr>
        </table>

        <table class="popupMobileTable" v-if="language === 'en'">
          <th>
            LEARN & STUDY
          </th>
          <tr>
            <td>
              <ul>
                <li>
                  Online 24/7 self-paced Bloodborne Pathogen Training
                </li>
                <li>
                  Specifically designed for body modification artists
                </li>
                <li>
                  Comprehensive and easy to understand
                </li>
                <li>
                  Course materials and exam available in English and Chinese
                </li>
              </ul>
            </td>
          </tr>
          <th>
            TAKE THE EXAM
          </th>
          <tr>
            <td>
              <ul>
                <li>
                  Up to 60 days to take and review the training before the exam
                </li>
                <li>
                  Online exam
                </li>
                <li>
                  Free retakes. You are guaranteed a certificate.
                </li>
              </ul>
            </td>
          </tr>
          <th>
            GET YOUR CERTIFICATE & SEAL
          </th>
          <tr>
            <td>
              <ul>
                <li>
                  Receive the digital version of your ABBP certificate immediately upon completion. Hardcopy to be delivered to your registered address shortly.
                </li>
                <li>
                  Certificate valid for 1 year
                </li>
                <li>
                  Receive your ABBP Quality Seal 
                </li>
                <li>
                  Endorsed by the Hong Kong Body Modification and Tattoo Artists Association
                </li>
              </ul>
            </td>
          </tr>
        </table>

        <h2 v-if="language === 'en'">Price includes:</h2>
        <ul v-if="language === 'en'">
          <li>
            Downloadable lecture summary in pdf format for revision and future reference
          </li>
          <li>
            Bloodborne Pathogen Exposure Record Keeping Template in pdf format
          </li>
        </ul>
        <h2 v-if="language === 'zh'">學費已包括：</h2>
        <ul v-if="language === 'zh'">
          <li>
            ABBP 培訓課程精讀筆記（pdf），下載後供複習或將來參考用途
          </li>
          <li>
            血源性病毒感染記錄表 pdf 範本
          </li>
        </ul>
        <h2 v-if="language === 'en'">Exclusive to certified Hong Kong Practitioners:</h2>
        <ul v-if="language === 'en'">
          <li>
            Receive ONE FREE sharp bin
          </li>
          <li>
            Enjoy FREE ONE-TIME sharp bin collection and disposal service
          </li>
          <li>
            Enjoy ONE session of Post-training In-Person Consultation and Inspection by ABBP highly-trained staff
          </li>
          <li>
            Get a FREE membership for the Hong Kong Body Modification and Tattoo Artists Association
          </li>
        </ul>
        <h2 v-if="language === 'zh'">通過證書課程後的禮遇（只限香港地區）：</h2>
        <ul v-if="language === 'zh'">
          <li>
            免費獲得醫療廢物收集及處置服務一次
          </li>
          <li>
            免費獲得 ABBP 專業團隊上門諮詢及檢查服務一節
          </li>
          <li>
            免費獲得標準醫療癈置箱一個
          </li>
          <li>
            免費獲得香港紋身師及穿環師協會會籍
          </li>
        </ul>
        <h2 v-if="language === 'en'">Course Overview:</h2>
        <ul v-if="language === 'en'">
          <li>
            Introduction to Bloodborne Pathogens
          </li>
          <li>
            Different types of Bloodborne Pathogens
          </li>
          <li>
            Methods to Control Risk of Exposure
          </li>
          <li>
            Emergency Procedures in case of Exposures
          </li>
          <li>
            Record Keeping
          </li>
          <li>
            Final Exam
          </li>
        </ul>
        <h2 v-if="language === 'zh'">課程一覽：</h2>
        <ul v-if="language === 'zh'">
          <li>
            血源性病毒簡介
          </li>
          <li>
            不同類型的血源性病毒
          </li>
          <li>
            醫療癈物棄置風險管理
          </li>
          <li>
            意外事故後的急救護理
          </li>
          <li>
            保存記錄的重要
          </li>
          <li>
            考試
          </li>
        </ul>
      </div>
      <div class="popupButtonWrapper">
        <div class="popupBtn" @click="signUpPopupToggle">
          <p class="popupBtnText" v-if="language === 'zh'">立即登記</p>
          <p class="popupBtnText" v-if="language === 'en'">SIGN UP NOW</p>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
</template>

<script>
export default {
  name: 'Course',
  computed: {
    aboutPopup() {
      return this.$store.getters.aboutPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    closePopup() {
      this.$store.commit("togglePopup", {popup: "aboutPopup", status: false})
    },
    signUpPopupToggle() {
      if (!this.signUpFormPopup) {
        if (!this.signUpFormPopup) {
          if(this.loggedIn) {
            this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
            // this.$store.commit("accountPopup", true)
            // this.$store.commit("aboutPopup", false)
          }
          else {
            this.$store.commit("togglePopup", {popup: "termsAndConditionsPopup", status: true})
            // this.$store.commit("signUpFormPopup", true)
            // this.$store.commit("aboutPopup", false)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/popupContent/popup-content.scss';
</style>
