<template>
<!-- <div class="aboutScrollWrapper"> -->
  <div class="popupWrapper">
    <div class="popupContent">
      <h1 v-if="language === 'en'" class="popupTitle">FAQ</h1>
      <h1 v-if="language === 'zh'" class="popupTitle">常見問題</h1>
      <img src="@/assets/close_btn_white.svg" class="closeBtn" @click="closePopup">
      <div class="popupTextWrapper">
        <h2 v-if="language === 'en'">
          1. Who should take this course?
        </h2>
        <p v-if="language === 'en'">
          All body modification practitioners including, but not limited to, tattoo artists, piercers, semi-permanent and permanent makeup specialists, and cosmetic tattoo specialists should take this course to enhance their workplace hygiene to professional standards since their job exposes them to blood and requires the handling of clinical waste on a daily basis.
        </p>
        <h2 v-if="language === 'zh'">
          1. 甚麼人適合報讀 ABBP 培訓課程？
        </h2>
        <p v-if="language === 'zh'">
          凡從事任何方式的人體藝術（body modification），即紋身師、穿環師、紋繡師等等，均適合報讀此課程。從事這些職業的人每天都會接觸顧客的血液，以及處置醫療廢物，過程中有機會面對健康風險，因此有必要把工作場所的衛生提升至專業水準。
        </p>
        <br>
        <h2 v-if="language === 'en'">
          2. Who is at risk if body modification artists do not follow preventive measurements strictly?
        </h2>
        <p v-if="language === 'en'">
          The artists themselves, their clients, and coworkers that share the same workspace, as they are often at risk for occupational exposure to blood. Mishandled sharps and other clinical wastes could also put the health of refuse collectors at risk.
        </p>
        <h2 v-if="language === 'zh'">
          2. 假如從業員沒有跟從衛生指引，有甚麼人會有機會面對健康風險？
        </h2>
        <p v-if="language === 'zh'">
          從業員自己、顧客、以及在同一個場所工作的員工，都有機會不慎接觸血液而承受健康風險。紋身、穿環、紋繡等人體藝術的過程中所用到的針頭和利器，若不適當地棄置，更有機會刺傷清潔人員。
        </p>
        <br>
        <h2 v-if="language === 'en'">
          3. How do I know if the artist is certified in bloodborne pathogens training?
        </h2>
        <p v-if="language === 'en'">
          All certified artists should display their certificate in a visible area of their work space. You may also consult our list of certified artists here.
        </p>
        <h2 v-if="language === 'zh'">
          3. 一般人如何得知從業員有沒有受過有關血源性病原體方面的培訓？
        </h2>
        <p v-if="language === 'zh'">
          一般而言，從業員會在工作場所的當眼處張貼有關證書。你亦可以按此了解已通過 ABBP 培訓的從業員的名單。
        </p>
        <br>
        <h2 v-if="language === 'en'">
          4. Why do we list all ABBP artists on our website?
        </h2>
        <p v-if="language === 'en'">
          We believe it is the public’s right to know who is trained. Our transparency allows the public to make confident and informed decisions when choosing their artists. 
        </p>
        <h2 v-if="language === 'zh'">
          4. 為何我們會在網站上列出所有通過 ABBP 培訓的從業員的名單？
        </h2>
        <p v-if="language === 'zh'">
          我們相信讓公眾知悉誰人曾經接受並通過有關訓練是必須的，可讓他們在考慮紋身、穿環或任何形式的紋繡活動之前，有充足的資訊供參考。
        </p>
        <br>
        <h2 v-if="language === 'en'">
          5. What are bloodborne pathogens?
        </h2>
        <p v-if="language === 'en'">
          Bloodborne pathogens are infectious microorganisms in human blood that can cause disease in humans. These pathogens include, but are not limited to, hepatitis B (HBV), hepatitis C (HCV) and human immunodeficiency virus (HIV). Needlesticks and other sharps-related injuries may expose workers to bloodborne pathogens. Workers in many occupations, including first responders, body modification artists, nurses and other healthcare personnel, all may be at risk for exposure to bloodborne pathogens.
        </p>
        <h2 v-if="language === 'zh'">
          5. 甚麼是血源性病毒？
        </h2>
        <p v-if="language === 'zh'">
          血源性病原體（又稱血源性病毒）是人血液中具傳染性、能夠引起疾病的細菌。許多職業，包括急救員、人體藝術從業員、醫護人員等等，有機會於工作時不慎因針刺意外，或經傷口接觸到各種經血液傳染的病毒，常見病毒包括乙型、丙型肝炎和愛滋病等等。
        </p>
        <br>
        <h2 v-if="language === 'en'">
          6. What can be done to control exposure to bloodborne pathogens?
        </h2>
        <p v-if="language === 'en'">
          Taking this certified training course equips body modification practitioners the necessary knowledge to take preventive measurements in order to minimise exposure to bloodborne pathogens. For example, how to correctly wear your protective gear, sterilise, and dispose of needles, etc.
        </p>
        <h2 v-if="language === 'zh'">
          6. 怎麼做才能夠預防血源性病毒感染？
        </h2>
        <p v-if="language === 'zh'">
          從業員能夠透過 ABBP 課程裝備有關知識，預防人體危害。例如：如何正確地穿戴手套及防護衣物、消毒、棄置針和尖銳物品等等。
        </p>
        <br>
        <h2 v-if="language === 'en'">
          7. What happens if I am exposed to bloodborne pathogens even when I have already taken preventive measures?
        </h2>
        <p v-if="language === 'en'">
          Unfortunately, accidents could happen. This training programme teaches you emergency procedures when you are exposed to bloodborne pathogens. 
        </p>
        <h2 v-if="language === 'zh'">
          7. 假如已經採取了預防方法，但仍然受到感染，我該怎麼辦？
        </h2>
        <p v-if="language === 'zh'">
          不幸地，有時候意外是無可避免的。ABBP 培訓課程教授發生針刺意外時的急救護理，以減低感染風險。
        </p>
        <br>
        <h2 v-if="language === 'en'">
          8. Why do I need to take this training every year?
        </h2>
        <p v-if="language === 'en'">
          A yearly recap is necessary to help practitioners stay up to date with knowledge and safety practices to safeguard you, your clients and coworkers against health hazards related to bloodborne pathogens.
        </p>
        <h2 v-if="language === 'zh'">
          8. 為甚麼我需要每年都接受培訓？
        </h2>
        <p v-if="language === 'zh'">
          每年一次的溫故知新，能夠幫助從業員有效預防人體危害，保障從業員自己、其顧客及同事的健康，減低血源性病毒的感染。
        </p>
        <br>
      </div>
      <div class="popupTextCentre">
        <p v-if="language === 'en'">
          Never risk your customers’ and your own health. 
          Gain customer trust and boost your business today.
        </p>
        <p v-if="language === 'zh'">
          拒絕任何健康風險. 從衛生管理提升專業 獲得更多客戶信任.
        </p>
      </div>
      <div class="popupButtonWrapper">
        <div class="popupBtn" @click="signUpPopupToggle">
          <p class="popupBtnText" v-if="language === 'zh'">立即登記</p>
          <p class="popupBtnText" v-if="language === 'en'">SIGN UP NOW</p>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
</template>

<script>
export default {
  name: 'Faq',
  computed: {
    aboutPopup() {
      return this.$store.getters.aboutPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    closePopup() {
      this.$store.commit("togglePopup", {popup: "aboutPopup", status: false})
    },
    signUpPopupToggle() {
      if (!this.signUpFormPopup) {
        if (!this.signUpFormPopup) {
          if(this.loggedIn) {
            this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
            // this.$store.commit("accountPopup", true)
            // this.$store.commit("aboutPopup", false)
          }
          else {
            this.$store.commit("togglePopup", {popup: "termsAndConditionsPopup", status: true})
            // this.$store.commit("signUpFormPopup", true)
            // this.$store.commit("aboutPopup", false)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/popupContent/popup-content.scss';
// .popupTextWrapper {
//   grid-column: 2/3;
//   grid-row: 2/3;
//   overflow: auto;
//   align-self: start;
//   height: 100%;

//   p {
//     text-align: left;
//     font-size: 14pt;
//   }
// }
// .popupTextCentre {
//   grid-column: 2/3;
//   grid-row: 3/4;
//   display: grid;

//   p {
//     align-self: start;
//     margin: 0;
//     font-style: italic;
//     font-size: 10pt;
//   }
// }
</style>
