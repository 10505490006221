<template>
  <div class="contactWrapper">
    <div class="contactContent">
      <p class="contactTitle" v-if="language === 'zh'">聯絡</p>
      <p class="contactTitle" v-if="language === 'en'">Contact Us</p>
      <img src="@/assets/close_btn_white.svg" alt="Close" class="closeBtn" @click="closePopup">
      <div class="contactTextWrapper">
        <!-- <div class="contactTextInfo">
          <img src="@/assets/location_icon.svg" alt="Address">
          <p>
            xxxxxxxx<br>
            xxxxxxxxxxxxx<br>
            123455<br>
            Hong Kong
          </p>
        </div>
        <div class="contactTextInfo">
          <img src="@/assets/phone_icon.svg" alt="Phone">
          <p>(000)000 000 000</p>
        </div> -->
        <div class="contactTextInfo">
          <img src="@/assets/email_icon.svg" alt="Email">
          <p><a href="mailto:info@asiabbp.com">info@asiabbp.com</a></p>
        </div>
      </div>
      <div class="contactInputWrapper" ref="contactInputWrapper">
        <input class="contactInput" type="text" placeholder="Your Name" name="name" v-model="name">
        <p class="warningText" v-show="nameWarningText">* This filed is empty</p>
        <input class="contactInput" type="text" placeholder="Your Email" name="email" v-model="email">
        <p class="warningText" v-show="emailWarningText">* This filed is empty</p>
        <p class="warningText" v-show="emailWarningText2">* Please enter a valid email address.</p>
      </div>
      <div class="contactSelectWrapper">
        <button 
          class="contactSelect"
          @click="openOptions"
        >
          <p>{{ selected }}</p>
          <img src="@/assets/down_icon.svg" alt="Select">
        </button>
        <p class="warningText" v-show="selectWarningText">* Please select one</p>
      </div>
      <ul class="contactSelectOptions" v-if="language === 'en'">
        <li 
          v-for="option in options.en"
          :class="{ hidden: hideSelect }"
          :key="option"
          @click="selectOption(option)"
        >
          {{ option }}
        </li>
      </ul>
      <ul class="contactSelectOptions" v-if="language === 'zh'">
        <li 
          v-for="option in options.zh"
          :class="{ hidden: hideSelect }"
          :key="option"
          @click="selectOption(option)"
        >
          {{ option }}
        </li>
      </ul>
      <div class="contactForm">
        <textarea class="contactInput" name="contactForm" id="contactForm" cols="20" rows="20" v-model="msg"></textarea>
        <p class="warningText" v-show="contactFormWarning">* This filed is empty</p>
      </div>
      <div class="contactButtonWrapper">
        <div class="contactSignUpBtn" @click="send">
          <p class="contactBtnText" v-if="language === 'zh'">發送</p>
          <p class="contactBtnText" v-if="language === 'en'">SEND</p>
        </div>
      </div>
    </div>
    <div class="contactPopup" v-show="thankyouPopup">
      <p>
        Thank you for your message, <br>we will get back to you promptly.
      </p>
      <button @click="closePopup">
        CLOSE
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPopup',
  data() {
    return {
      selected: "Please select...",
      options: {
        'zh': [
          '更改個人或工作資料',
          '投訴',
          '其他'
        ],
        'en': [
          'Change of personal or work information',
          'Complaint',
          'Other'
        ]
      },
      name: "",
      email: "",
      msg: "",
      nameWarningText: false,
      emailWarningText: false,
      emailWarningText2: false,
      selectWarningText: false,
      contactFormWarning: false,
      hideSelect: true,
      thankyouPopup: false
    }
  },
  computed: {
    contactPopup() {
      return this.$store.getters.contactPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    closePopup() {
      this.$store.commit("togglePopup", {popup: "contactPopup", status: false})
    },
    openOptions() {
      this.hideSelect = !this.hideSelect;
    },
    selectOption(option) {
      this.selected = option;
      this.hideSelect = true;
    },
    send() {
      const checkAllInput = this.checkAllInput();
      if (checkAllInput) {
        this.sendEmail()
        this.thankyouPopup = true;
      } else {
        console.log('Not passed');
      }
    },
    emailCheck() {
      let emailCheck;
      if(!this.email) {
        this.emailWarningText = true;
      }
      else {
        if(!this.email.includes("@") || !this.email.includes(".")) {
          this.emailWarningText2 = true;
        }
        else {
          emailCheck = true;
          this.checkEmail = true;
          console.log("Email Check Passed");
          return emailCheck;
        }
      }
    },
    checkEmpty() {
      const textInput = document.querySelectorAll('.contactInput');
      let nameCheck;
      let emailCheck;
      let selectCheck;
      let contactFormCheck;

      textInput.forEach(input => {
        if(!input.value) {
          console.log(input.name + "Input field empty");
          if(input.name === "name") {
            this.nameWarningText = true;
          }
          if(input.name === "email") {
            this.emailCheck()
          }
          if(input.name === "contactForm") {
            this.contactFormWarning = true;
          }
        } else {
          if(input.name === "name") {
            nameCheck = true;
            return nameCheck;
          }
          if(input.name === "email") {
            emailCheck = true;
            return emailCheck;
          }
          if(input.name === "contactForm") {
            contactFormCheck = true;
            return contactFormCheck;
          }
        }
      })

      if (this.selected === "Please select..." || "") {
        this.selectWarningText = true;
      } else {
        selectCheck = true;
        return selectCheck;
      }
    },
    checkAllInput() {
      this.nameWarningText = false;
      this.emailWarningText = false;
      this.emailWarningText2 = false;
      this.selectWarningText = false;
      this.contactFormWarning = false;
      const emailCheck = this.emailCheck();
      const checkEmpty = this.checkEmpty();
      if (emailCheck && checkEmpty) {
        return true;
      } else {
        return false;
      }
    },
    sendEmail() {
      const contactData = {
        senderName: this.name,
        senderEmail: this.email,
        subject: this.selected,
        msg: this.msg
      };
      fetch("https://service.asiabbp.com/send-contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
p, h1, h2, a {
  color: white;
}

::-webkit-scrollbar {
    display: none;
}
.contactScrollWrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.contactWrapper {
  grid-column: 1/2;
  grid-row: 1/4;
  width: 100%;
  height: 100%;
  z-index: 20;
  justify-self: center;
  align-self: flex-start;
  display: grid;
  margin: 0;

  @media only screen and (min-width: 768px) {
    grid-row: 2/3;
  }

  .warningText {
    font-size: 8pt;
    color: red;
    text-align: left;
    margin: 0.5em 0 0 1em;
  }

  .contactContent {
    grid-column: 1/2;
    grid-row: 1/2;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 8% 84% 8%;
    grid-template-rows: auto min-content min-content min-content;
    background-color: #202125;
    border-radius: 0;
    justify-self: center;
    align-self: center;
    overflow: auto;
    opacity: 1;

    @media only screen and (min-width: 768px) {
      width: 90%;
      border-radius: 1.5em;
      opacity: 0.8;
    }

    @media only screen and (min-width: 1024px) {
      width: 80%;
    }
  }
  .contactTitle {
    grid-column: 2/3;
    grid-row: 1/2;
    font-size: 20pt;
    font-weight: bold;
    text-align: left;
    align-self: center;
    margin: 7% 0 5% 0;

    @media only screen and (min-width: 768px) {
      width: 100%;
      font-size: 26pt;
    }
  }
  .closeBtn {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: end;
    align-self: start;
    height: 25px;
    margin-top: 6.5%;
    cursor: pointer;
  }
  .contactTextWrapper {
    grid-column: 2/3;
    grid-row: 2/3;
    overflow: auto;
    align-self: start;
    height: 100%;
    display: grid;
    grid-template-columns: max-content;

    h2 {
      font-size: 14pt;
      grid-column: 1/3;

      @media only screen and (min-width: 768px) {
        font-size: 18pt;
      }
    }

    p {
      text-align: left;
      font-size: 12pt;

      @media only screen and (min-width: 768px) {
        font-size: 14pt;
      }
    }

    .contactTextInfo {
      display: grid;
      grid-template-columns: max-content auto;
      margin: 0 0 1em;

      img {
        grid-column: 1/2;
        grid-row: 1/2;
        width: 40px;
        height: 40px;
      }
      p {
        grid-column: 2/3;
        grid-row: 1/2;
        text-align: left;
        font-size: 14pt;
        align-self: center;
        margin: 0 0 0 1em;

        @media only screen and (min-width: 768px) {
          font-size: 18pt;
        }
      }
    }
  }

  .contactInputWrapper {
    grid-column: 2/3;
    grid-row: 3/4;
    margin-bottom: 1em;

    input {
      width: calc(100% - 2em);
      font-family: Poppins, Helvetica, Arial, sans-serif;
      font-size: 10pt;
      padding: 0.5em 1em;
      border-width: 0;
      margin: 1em 0 0;

      @media only screen and (min-width: 768px) {
        font-size: 12pt;
      }
    }
  }

  .contactSelectWrapper {
    grid-column: 2/3;
    grid-row: 4/5;
    margin: 0 0 1em;

    .contactSelect {
      display: grid;
      height: 50px;
      width: 100%;
      padding: 0;
      border-width: 0;
      cursor: pointer;

      p {
        font-size: 10pt;
        font-family: Poppins, Helvetica, Arial, sans-serif;
        text-align: left;
        color: #202125;
        justify-self: start;
        align-self: center;
        margin: 0;
        padding: 0.3em 1em;

        @media only screen and (min-width: 768px) {
          font-size: 12pt;
          font-weight: 700;
        }
      }

      img {
        grid-column: 2/3;
        justify-self: end;
        align-self: center;
        margin: 0 1em;
        width: 18px;
      }
    }
  }

  .contactSelectOptions {
    grid-column: 2/3;
    grid-row: 5/6;
    height: fit-content;
    z-index: 5;
    background-color: #FFFFFF;
    list-style-type: none;
    padding: 0;
    margin: -0.8em 0 0;
    border-radius: 2px;
    box-shadow: 1px 1px 8px #2b2b2b;
    font-size: 10px;

    @media only screen and (min-width: 768px) {
      font-size: 12pt;
    }

    li {
      cursor: pointer;
      text-indent: 1em;
      margin: 10px 0;
    }
  }
  .contactForm {
    grid-column: 2/3;
    grid-row: 5/6;
    display: grid;

    textarea {
      width: calc(100% - 2em);
      height: 200px;
      resize: none;
      min-height: 100px;
      font-family: Poppins, Helvetica, Arial, sans-serif;
      font-size: 10pt;
      padding: 1em;

      @media only screen and (min-width: 768px) {
        font-size: 12pt;
      }
    }
  }
  .contactButtonWrapper {
    grid-column: 2/3;
    grid-row: 6/7;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding: 2em 0 2em 0;

    @media only screen and (min-width: 768px) {
      padding: 4% 0 5% 0;
    }
  }
  .contactSignUpBtn {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: center;
    justify-self: center;
    height: 46px;
    width: 232px;
    background-color: #F66F51;
    display: grid;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    opacity: 1;
  }
  .contactSignUpBtn:hover {
    background-color: black;
  }
  .contactCancelBtn {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: center;
    justify-self: center;
    height: 46px;
    width: 232px;
    background-color: #F66F51;
    display: grid;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    opacity: 1;
  }
  .contactCancelBtn:hover {
    background-color: black;
  }
  .contactBtnText {
    color: white;
    align-self: center;
    font-size: 12pt;
    margin: 0;
    align-self: center;
    justify-self: center;
  }

  .hidden {
    display: none;
  }

  .contactPopup {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 380px;
    width: 500px;
    justify-self: center;
    align-self: center;
    background-color: white;
    border-radius: 1.2em;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 70% 30%;
    z-index: 25;

    p {
      text-align: center;
      color: #202125;
      align-self: center;
      justify-self: center;
      margin-top: 15%;
    }

    button {
      width: 130px;
      height: 46px;
      border-width: 2pt;
      border-radius: 3.5em;
      background-color: white;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      justify-self: center;
      align-self: start;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}
</style>
