<template>
  <div class="popupWrapper">
    <div class="popupContent">
      <h1 class="popupTitle" v-if="language === 'zh'">關於</h1>
      <h1 class="popupTitle" v-if="language === 'en'">About</h1>
      <img src="@/assets/close_btn_white.svg" class="closeBtn" @click="closePopup">
      <div class="popupTextWrapper">
        <p v-if="language === 'en'">
          Body art is beautiful because it embodies self-expression in so many ways. As body modification – tattooing, piercing, microblading, lip blushing and other forms of cosmetic tattooing – becomes more and more widely accepted in Asia, one should not overlook the potential health risks that come hand in hand with this aesthetic practice.
        </p>
        <p v-if="language === 'zh'">
          人體藝術美麗之處，在於其多姿多彩的表達方式。在歐美地區，紋身刺青、穿環、各種紋繡如紋眉、紋唇、半永久化妝等等，廣泛被稱為「人體藝術」（body modification）。在亞洲地區，隨著人體藝術愈來愈普及，隨之而來的健康風險亦不容忽視。
        </p>
        <p v-if="language === 'zh'">
          <strong>「ABBP 亞洲人體藝術衛生管理課程」</strong>是亞洲人體藝術教育學院（Asia Tattoo and Piercing Education）提供的眾多專業培訓之一。課程由理念一致的本地及海外專業團隊所設計，成員背景包括香港註冊醫生、香港註冊防疫控感染專職護士、社區醫學與衛生管理專員，以及經驗豐富的職業紋身師及穿環師。
        </p>
        <p v-if="language === 'en'">
          <strong>ABBP</strong> is one of the many professional trainings offered by Asia Tattoo and Piercing Education. The course is created and run by an all-round team of experienced local and overseas body modification practitioners, public health specialists, specialist registered nurse in infection control, and registered doctors based in Hong Kong who share the same vision.
        </p>
        <h2 v-if="language === 'en'">Vision</h2>
        <h2 v-if="language === 'zh'">理念</h2>
        <p v-if="language === 'en'">
         <strong>ABBP’s</strong> vision is to promote the safe practice of body modification for all individuals as we believe that aesthetics should not come at the expense of health and safety. Through the <strong>ABBP</strong>, we hope to elevate hygiene standards in the industry to an international level, raise public awareness about the importance of practising safe body modification, and ultimately protect the health of both the artists and clients.
        </p>
        <p v-if="language === 'zh'">
          <strong>ABBP</strong> 提倡安全至上的人體藝術，我們相信任何美體的行為都應該以健康和安全為首要考慮。透過<strong>ABBP</strong>，我們希望將目前亞洲業界的衛生水平提升至國際標準，同時提高大眾對於人體藝術的安全意識，提升職業紋身師、穿環師、紋繡師等從業員的專業，以及保障其客戶的健康。
        </p>
        <h2 v-if="language === 'en'">Bloodborne Pathogen Online Course & Certification</h2>
        <h2 v-if="language === 'zh'">「ABBP 亞洲人體藝術衛生管理」培訓：網上專業證書課程</h2>
        <p v-if="language === 'en'">
          <strong>ABBP</strong> offers the first professional online bloodborne pathogen training course specifically designed for body modification practitioners working in Asia including, but not limited to, tattoo artists, piercers, semi-permanent and permanent makeup specialists. 
        </p>
        <p v-if="language === 'zh'">
          <strong>ABBP</strong> 提供首個專為亞洲地區而設、針對有關「血源性病原體」方面的網上專業證書課程。凡從事人體藝術行業，包括職業紋身師、穿環師、紋繡師等等，均適合報讀課程。
        </p>
        <h2 v-if="language === 'en'">ABBP Quality Seal</h2>
        <h2 v-if="language === 'zh'">ABBP 衛生認證</h2>
        <p v-if="language === 'en'">
          <strong>ABBP</strong> rewards all certified body modification practitioners with the “ABBP Quality Seal” to show that the workplace of the practitioners has met professional health and safety standards.
        </p>
        <p v-if="language === 'zh'">
          <strong>ABBP</strong> 會向所有通過考試的人體藝術從業員頒發「ABBP 衛生認證」，以證明從業員的工作場所符合專業衛生及安全標準。
        </p>
        <h2 v-if="language === 'en'">Clinical Waste Management</h2>
        <h2 v-if="language === 'zh'">醫療廢物管理</h2>
        <p v-if="language === 'en'">
          Used or contaminated sharps are considered as clinical waste and should be properly disposed of to prevent the spread of biohazard. <strong>ABBP</strong>’s complimentary sharp bin, as well as our collection and disposal service adhere to the regulations stated by the Hong Kong Environmental Protection Department.
        </p>
        <p v-if="language === 'zh'">
          防止因血源性病毒傳播所造成的危害，使用過的銳器如紋身、紋繡及穿環針，均屬於醫療廢物，因此需要妥善處置。<strong>ABBP</strong> 在學員完成和通過證書課程後所贈送的醫療癈置箱，以及收集和處置醫療廢物的服務，均符合香港環境保護署的規定。
        </p>
      </div>
      <div class="popupButtonWrapper">
        <div class="popupBtn" @click="signUpPopupToggle">
          <p class="popupBtnText" v-if="language === 'zh'">立即登記</p>
          <p class="popupBtnText" v-if="language === 'en'">SIGN UP NOW</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  computed: {
    aboutPopup() {
      return this.$store.getters.aboutPopup;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    closePopup() {
      this.$store.commit("togglePopup", {popup: "aboutPopup", status: false})
    },
    signUpPopupToggle() {
      if (!this.signUpFormPopup) {
        if (!this.signUpFormPopup) {
          if(this.loggedIn) {
            this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
            // this.$store.commit("accountPopup", true)
            // this.$store.commit("aboutPopup", false)
          }
          else {
            this.$store.commit("togglePopup", {popup: "termsAndConditionsPopup", status: true})
            // this.$store.commit("signUpFormPopup", true)
            // this.$store.commit("aboutPopup", false)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/popupContent/popup-content.scss';
</style>
