<template>
<div class="paypalWrapper" ref="paypalButtonContainer">
</div>
</template>

<script>
import { db } from '@/components/firebase.js';
import { loadScript } from "@paypal/paypal-js"
export default {
  name: 'CardPayment',
  data() {
    return {
      purchase: {
        items: "lecture-exam"
      }
    }
  },
  computed: {
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    paymentFor() {
      return this.$store.getters.paymentFor;
    },
  },
  methods: {
    cardPaymentBackBtn() {
      this.$store.commit("changePaymentPopup", "paymentMethods");
    },
    loadPaypal() {
      loadScript({ "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID, "currency": "HKD" })
      .then((paypal) => {
        paypal.Buttons({
          // Order is created on the server and the order id is returned
          createOrder() {
            return fetch("https://service.asiabbp.com/create-paypal-order", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              // use the "body" param to optionally pass additional order information
              // like product skus and quantities
              body: JSON.stringify({
                cart: [
                  {
                    sku: "YOUR_PRODUCT_STOCK_KEEPING_UNIT",
                    quantity: "YOUR_PRODUCT_QUANTITY",
                  },
                ],
              }),
            })
            .then((response) => response.json())
            .then((order) => order.id);
          },
          // Finalize the transaction on the server after payer approval
          onApprove(data) {
            return fetch("https://service.asiabbp.com/capture-paypal-order", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                orderID: data.orderID
              })
            })
            .then((response) => response.json())
            .then((orderData) => {
              const transaction = orderData.purchase_units[0].payments.captures[0];
              if (transaction.status === 'COMPLETED'){
                const completeEvent = new CustomEvent("complete", {detail: transaction});
                dispatchEvent(completeEvent);
              } else {
                const failEvent = new Event("fail");
                dispatchEvent(failEvent);
              }
            });
          }
        }).render(this.$refs.paypalButtonContainer);
      })
      .catch((err) => {
          console.error("failed to load the PayPal JS SDK script", err);
      });
    },
    updateAccount(transactionData) {
      let userData = db.collection("user_data").doc(this.userEmail)
      let paymentFor = this.paymentFor + "Purchase";
      return userData
      .update({
        "payment": true,
        [paymentFor]: true,
        "paypalTransactionData": transactionData
      })
      .then(() => {
        console.log("Account data updated");
      })
      .catch((err) => {
        console.log("Account data updated error: " + err);
      })
    },

  },
  mounted() {
    this.loadPaypal()
    window.addEventListener('complete', (e) => {
      this.updateAccount(e.detail);
      this.$store.commit("changePaymentPopup", "paymentStatus");
      this.$store.commit("changePaymentStatus", "success");
    })
    window.addEventListener('fail', () => {
      this.$store.commit("changePaymentPopup", "paymentStatus");
      this.$store.commit("changePaymentStatus", "fail");
    })
  },
  // beforeDestroy() {
  //   window.removeEventListener('complete')
  //   window.removeEventListener('fail')
  // }
}
</script>

<style scoped>
.paypalWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1em;
}
</style>
