<template>
  <div class="loginPopup">
    <div class="loginLoading hidden" ref="loginLoading">
      <Loading />
    </div>
    <div class="loginText">
      <h1 v-if="language === 'zh'">
        登入
      </h1>
      <h1 v-if="language === 'en'">
        LOGIN
      </h1>
      <img src="@/assets/close_btn_white.svg" class="loginCloseBtn" @click="loginCloseBtn">
    </div>
    <div class="emailInputWrapper">
      <p v-if="language === 'zh'">電郵</p>
      <p v-if="language === 'en'">Email</p>
      <input class="textArea emailTextarea" ref="emailTextarea" type="email" @keyup.enter="submitLogin" v-model="email">
    </div>
    <div class="passwordInputWrapper">
      <p v-if="language === 'zh'">密碼</p>
      <p v-if="language === 'en'">Password</p>
      <input class="textArea passwordTextarea" ref="passwordTextarea" type="password" @keyup.enter="submitLogin" v-model="password">
    </div>
    <div class="forgotPassword">
      <p v-if="language === 'zh'" @click="resetPassword">忘記密碼?</p>
      <p v-if="language === 'en'" @click="resetPassword">Forgot your password?</p>
    </div>
    <div class="loginBtnWrapper">
      <div class="btn loginBtn" @click="submitLogin">
        <p v-if="language === 'zh'" class="loginBtnText">登入</p>
        <p v-if="language === 'en'" class="loginBtnText">LOGIN</p>
      </div>
      <div class="btn loginSignUpBtn" @click="signUpPopupToggle">
        <p v-if="language === 'zh'" class="loginSignUpBtnText">註冊</p>
        <p v-if="language === 'en'" class="loginSignUpBtnText">SIGN UP</p>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading.vue';
export default {
  name: 'LoginPopup',
  components: { Loading },
  data() {
    return {
      email: "",
      password: "",
    }
  },
  computed: {
    loginPopup() {
      return this.$store.getters.loginPopup;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    loginCloseBtn() {
      this.$store.commit("togglePopup", {popup: "loginPopup", status: false})
    },
    async submitLogin() {
      const loginData = {
        email: this.email,
        password: this.password
      }
      this.$refs.loginLoading.classList.remove('hidden')
      const submitLogin = await this.$store.dispatch('submitLogin', loginData);
      if(!submitLogin) {
        this.$refs.loginLoading.classList.add('hidden')
      }
      // try {
      //   const user = await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
      //   console.log("Logged In");
      //   // this.$store.commit("updateUserData", user.user)
      //   // this.$store.commit("loginPopup", false)
      //   this.$store.commit("togglePopup", {popup: "accountPopup", status: true})
      //   // this.$store.commit("accountPopup", true)
      //   return user.user;
      // } 
      // catch(err) {
      //   console.log(err);
      //   alert(err);
      // }
    },
    signUpPopupToggle() {
      this.$store.commit("togglePopup", {popup: "termsAndConditionsPopup", status: true})
      // if (!this.signUpFormPopup) {
      //   this.$store.commit("signUpFormPopup", true)
      // }
    },
    resetPassword() {
      // this.$store.commit("passwordResetPopup", true);
      this.$store.commit("togglePopup", {popup: "passwordResetPopup", status: true})
    }
  }
}
</script>

<style lang="scss" scoped>
h1, p {
  color: white;
}
.loginPopup {
  background-color: #202125;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 21% 23% 23% 4% 29%;
  opacity: 0.8;
}
.loginLoading {
  grid-column: 1/2;
  grid-row: 1/7;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2em;
  background-color: #BFBFBF;
  opacity: .7;
  z-index: 50;
}
.loginText {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;

  h1 {
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
    align-self: center;
    font-size: 18pt;
    font-weight: bold;
    margin: 6% 0 0 0;
    z-index: 0;

    @media only screen and (min-width: 768px) {
      font-size: 22pt;
    }
  }
}
.loginCloseBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  height: 20px;
  margin: 8% 8% 0 0;
  cursor: pointer;
  z-index: 20;

  @media only screen and (min-width: 768px) {
    height: 25px;
    margin: 6% 6% 0 0;
  }
}
.emailInputWrapper {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 70%;
  justify-self: center;
  display: grid;

  p {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: 12pt;
    text-align: left;
    margin: 0 0 0 0.5em;
    align-self: end;

    @media only screen and (min-width: 768px) {
      font-size: 15pt;
    }
  }
}

.textArea {
  width: 100%;
  height: 38px;
  resize: none;
  border-style: solid;
  border-width: 2pt;
  border-radius: 1em;
  font-size: 12pt;
  align-self: center;
  padding: 0 3% 0 3%;

  @media only screen and (min-width: 768px) {
    height: 45px;
    font-size: 14pt;
  }
}

.emailTextarea {
  grid-column: 1/2;
  grid-row: 2/3;
}
.passwordInputWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  width: 70%;
  justify-self: center;
  display: grid;

  p {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: 12pt;
    text-align: left;
    margin: 0 0 0 0.5em;
    align-self: end;

    @media only screen and (min-width: 768px) {
      font-size: 15pt;
    }
  }
}

.passwordTextarea {
  grid-column: 1/2;
  grid-row: 2/3;
}
.forgotPassword {
  grid-column: 1/2;
  grid-row: 4/5;
  width: 70%;
  justify-self: center;
  text-align: left;
  display: grid;

  p {
    margin: 0 0 0 0.9em;
    font-size: 8pt;
    text-decoration: underline;
    cursor: pointer;
    align-self: start;

    @media only screen and (min-width: 768px) {
      font-size: 10pt;
    }
  }
}

.loginBtnWrapper {
  grid-column: 1/2;
  grid-row: 5/6;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  width: 70%;
  justify-self: center;
}

.btn {
  width: 100px;
  height: 38px;

  p {
    font-size: 10pt;
  }

  @media only screen and (min-width: 768px) {
    width: 130px;
    height: 46px;

    p {
      font-size: 12pt;
    }
  }
}

.loginBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  background-color: #3BC146;
  border-style: solid;
  border-width: 2pt;
  border-radius: 3.5em;
  border-color: #3BC146;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: black;
    border-color: black;
  }
}

.loginSignUpBtn {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  background-color: white;
  border-style: solid;
  border-width: 2pt;
  border-radius: 3.5em;
  display: grid;
  cursor: pointer;
  color: black;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: black;
    border-color: black;
    color: white;
  }
}

.loginBtnText {
  justify-self: center;
  align-self: center;
  margin: 0;
}
.loginSignUpBtnText {
  justify-self: center;
  align-self: center;
  color: black;
  margin: 0;
}
.hidden {
  display: none;
}
</style>
