<template>
  <div class="certWrapper">
    <div class="certBtnWrapper">
      <img src="@/assets/back_btn.svg" class="certBackBtn" @click="certBackBtn">
      <img src="@/assets/back_btn.svg" class="certBackBtn" @click="certBackBtn">
      <!-- <img src="@/assets/download_icon_white.svg" class="certBtn" ref="download" @click="createPdf"> -->
      <!-- <span class="certBtn">
      </span> -->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 188" class="certBtn" @click="createPdf">
        <g id="Layer_2" data-name="Layer 2"><g id="Account_-_certificate" data-name="Account - certificate"><rect x="1.5" y="1.5" width="230" height="185" rx="20" style="stroke:#fff;stroke-miterlimit:10;stroke-width:3px"/><path d="M161.8,99.41l-12-25.67a1.89,1.89,0,0,0-1.91-1.06H137.72a2.12,2.12,0,1,0,0,4.24h8.8l9.87,21.22H144.3a13.88,13.88,0,0,0-12.73,8.17,9.89,9.89,0,0,1-8.92,5.62h-12.3a9.89,9.89,0,0,1-8.92-5.62A13.88,13.88,0,0,0,88.7,98.14H76.82L88.49,76.92h6.79a2.12,2.12,0,1,0,0-4.24H87.22a2,2,0,0,0-1.91.95L71.3,99.3a1.33,1.33,0,0,0-.42.85v26.42a11.14,11.14,0,0,0,10.93,10.82h69.38a11.14,11.14,0,0,0,10.93-10.82V100.15A1.58,1.58,0,0,0,161.8,99.41Zm-3.92,27.16a6.91,6.91,0,0,1-6.69,6.58H81.81a6.91,6.91,0,0,1-6.69-6.58V102.38H88.7A9.89,9.89,0,0,1,97.62,108a13.88,13.88,0,0,0,12.73,8.17h12.3A13.88,13.88,0,0,0,135.38,108a9.89,9.89,0,0,1,8.92-5.62h13.58v24.19Z" style="fill:#fff"/><path d="M115,99.73a2,2,0,0,0,2.85.12l.13-.12,14.42-14.54a2.11,2.11,0,0,0-.42-3,2.15,2.15,0,0,0-2.55,0L118.62,93V52.73a2.12,2.12,0,0,0-4.24,0V93.05L103.56,82.22a2.12,2.12,0,0,0-3,.43,2.1,2.1,0,0,0,0,2.54Z" style="fill:#fff"/></g></g>
      </svg>
      
    </div>
    <div class="certPreviewWrapper" ref="certPreviewWrapper" id="section-to-print">
      <!-- <img src="@/assets/certificate_template.svg" class="certBg" ref="certTemplate"> -->
      <p class="certName">
        {{ userData.englishName }} <span>({{ id }}****)</span>
      </p>
      <p class="completeDate certData">
        {{ completeDate }}
      </p>
      <p class="expiryDate certData">
        {{ expiryDate }}
      </p>
      <p class="ref certData">
        {{ ref }}
      </p>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { db } from '@/components/firebase.js';
export default {
  name: 'CertificateDownload',
  data() {
    return {
      completeDate: null,
      expiryDate: null,
      ref: null,
      id: null
    }
  },
  components: {
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
    userEmail() {
      return this.$store.getters.getUserEmail;
    }
  },
  methods: {
    certBackBtn() {
      this.$router.push('/');
      this.$store.commit("accountPopup", true);
      this.$store.commit('accountPage', "certificate");
    },

    createPdf() {
      let cert = this.$refs.certPreviewWrapper;
      cert.classList.add('fullScale')
      const doc = new jsPDF('l', 'pt', 'a4');

      html2canvas(cert, {
        allowTaint: true,
        useCORS: true,
        scale: 1
      })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgProps = doc.getImageProperties(imgData);
        const pdfw = doc.internal.pageSize.getWidth();
        const pdfh = (imgProps.height*pdfw)/imgProps.width;
        doc.addImage(imgData, 'PNG', 0, 0, pdfw, pdfh);
        doc.save('Certificate.pdf');
        cert.classList.remove('fullScale')
      })
    },

    async newDate() {
      const userDb = db.collection("user_data").doc(this.userEmail);
      const userData = await userDb.get();
      let examCompleteDate = new Date(userData.data().examCompleteDate*1000);
      let options = { day: 'numeric', month: 'long', year: 'numeric' };
      let date = examCompleteDate.toLocaleDateString("en-GB", options);
      let newDate = examCompleteDate.setSeconds(examCompleteDate.getSeconds() + 31449600);
      let expiryDate = new Date(newDate).toLocaleDateString("en-GB", options);
      this.completeDate = date;
      this.expiryDate = expiryDate;
      this.ref = userData.data().certificateRef;
      this.id = userData.data().passport.substring(0, 4);
    }
  },
  mounted() {
    this.newDate()
  }
}

</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}
.certWrapper {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 8% 84% 8%;
  grid-template-rows: 10% 90%;
  background-color: #202125;
}

.certBtnWrapper {
  grid-column: 1/4;
  grid-row: 1/2;
  display: grid;
  background-color: #202125;
  grid-template-columns: 15% 9% 54% 7% 15%;
  grid-template-rows: 100%;
  z-index: 50;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 8% 27% 32% 25% 8%;
  }
}
.certBackBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  height: 1.2em;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    height: 1.5em;
  }
}
.certBtn {
  grid-column: 3/4;
  grid-row: 1/2;
  height: 65%;
  width: auto;
  justify-self: center;
  align-self: center;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  g {
    g {
      rect {
        transition: 0.3s ease-in-out !important;
        fill: transparent;
      }
    }
  }

  &:hover {
    g {
      g {
        rect {
          fill: #3bc146 !important;
        }
      }
    }
  }
}

.certPreviewWrapper {
  grid-column: 2/3;
  grid-row: 2/3;
  background-color: white;
  width: 79.75em;
  height: 56.8125em;
  justify-self: center;
  align-self: center;
  background-image: url("~@/assets/certificate_template.svg");
  // background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 12% 66.5% 1.3% 20.2%;
  grid-template-rows: 33% 8% 29.6% 2.8% 3% 2.8% 2.8% 2.8% 15.2%;
  transform: scale(.25);

  @media only screen and (min-width: 768px) {
    align-self: start;
    transform: scale(0.5);
  }

  @media only screen and (min-width: 1024px) {
    transform: scale(0.7);
  }

  @media only screen and (min-width: 1280px) {
    transform: scale(1);
  }
}
.fullScale {
  transform: scale(1) !important;
}
.certBg {
  grid-column: 1/5;
  grid-row: 1/8;
  width: 1276px;
  height: 909px;
  justify-self: center;
  align-self: start;
  z-index: 10;
}
.certName {
  grid-column: 2/3;
  grid-row: 2/3;
  font-size: 32pt;
  font-weight: 500;
  justify-self: start;
  align-self: center;
  z-index: 10;

  span {
    font-size: 13pt;
    margin-left: 0.8em;
  }
}

.certData {
  font-size: 16pt;
  font-weight: 500;
  justify-self: start;
  align-self: center;
  margin: 0;
  z-index: 10;
}

.completeDate {
  grid-column: 4/5;
  grid-row: 4/5;
}
.expiryDate {
  grid-column: 4/5;
  grid-row: 6/7;
}
.ref {
  grid-column: 4/5;
  grid-row: 8/9;
}
.upScale {
  transform: scale(2,2);
}
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
/* @media only screen and (max-height: 1440px)  and (orientation: landscape) {
  .certPreviewWrapper {
    width: 48.6%;
    height: 50%;
  }
} */
</style>
