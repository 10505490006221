<template>
<div class="examWrapper">
  <div class="examBtnStatus">
    <img src="@/assets/close_btn_white.svg" class="examWrapperBackBtn" @click="showWarning">
    <div class="examProgressWrapper">
      <div class="examProgressBarWrapper">
        <span class="examProgressBar" :style="{ width: examProgress }"></span>
      </div>
      <p class="examProgress">
        {{ examProgress }}
      </p>
    </div>
    <div class="langBtn" @click="languageChange">
      <p v-if="language === 'en'">EN</p>
      <p v-if="language === 'zh'">中</p>
    </div>
  </div>
  <!-- <img src="@/assets/lecture_next_btn.svg" class="examNextBtn" ref="examNextBtn" @click="nextQuestion" v-show="examAnwsered"> -->
    <div class="examContentWrapper" ref="examContentWrapper">
      <ExamQ class="examContent" @nextQuestion="nextQuestion" :examData="sortedExam[this.examPg]" @examPassed="examResult" />
    </div>
  <div class="explainPopupWrapper" v-if="explainPopup">
    <div class="explainPopupBg"></div>
    <div class="explainPopup">
      <p class="explainPopupText">
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
      </p>
      <div class="explainPopupBtn" @click="nextQuestion">
        <p class="leaveBtnText">NEXT</p>
      </div>
    </div>
  </div>
  <div class="leaveWarningWrapper" v-if="leaveWarning">
    <div class="leaveWarningBg"></div>
    <div class="leaveWarning">
      <p class="leaveWarningText">
        By clicking confirm, you are leaving the exam and you will have to start from the beginning next time.
      </p>
      <div class="leaveWarningBtnWrapper">
        <div class="leaveBtn" @click="examWrapperBackBtn">
          <p class="leaveBtnText">LEAVE</p>
        </div>
        <div class="cancelBtn" @click="hideWarning">
          <p class="cancelBtnText">CANCEL</p>
        </div>
      </div>
    </div>
  </div>
  <div class="leaveWarningWrapper" v-if="finishPopup">
    <div class="leaveWarningBg"></div>
    <div class="leaveWarning">
      <div class="leaveWarningText" v-if="language === 'en'">
        <p v-if="examPassed">
          <span>Congratulations</span>, you have passed the ABBP exam. You can download your e-certificate and templates. You will also receive your ABBP seal, certificate, and complimentary items (if applicable) at your mailing address in 2 weeks.
        </p>
        <p v-else>
          Ops, please try again. You can redo the exam for unlimited times within 60 days after you subscribed to this course.
        </p>
      </div>
      <div class="leaveWarningText" v-if="language === 'zh'">
        <p v-if="examPassed">
          <span>恭喜你考試合格！</span> 現在你可以下載你的電子證書和參考範本。另外，你將於兩星期內於你的登記地址收到「ABBP衛生認證」貼紙 (請張貼在你的工作場所當眼處)、實體版證書及贈品（如適用）。
        </p>
        <p v-else>
          哎呀，不合格。不用擔心，由你登記此課程起計60天內，你可以無限次重做這個考試。再溫習一下，加油！
        </p>
      </div>
      <div class="leaveWarningBtnWrapper">
        <div class="leaveBtn" @click="examWrapperBackBtn">
          <p class="leaveBtnText">LEAVE</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { db } from '@/components/firebase.js';
import allExamData from './data/examQ.json';
import ExamQ from '@/components/exam/ExamQ.vue';
export default {
  name: 'ExamWrapper',
  data() {
    return {
      examProgress: "0%",
      leaveWarning: false,
      finishPopup: false,
      examPassed: null,
      sortedExam: []
    }
  },
  components: {
    ExamQ
  },
  computed: {
    examPg() {
      return this.$store.getters.getExamPg;
    },
    loggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    language() {
      return this.$store.getters.getLanguage;
    },
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    examAnwsered() {
      return this.$store.getters.getExamAnswered;
    },
    explainPopup() {
      return this.$store.getters.getExplainPopup;
    },
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  methods: {
    getExamData() {
      const n = 20;
      const sortExam = allExamData
        .map(x => ({ x, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map(a => a.x)
        .slice(0, n);
      this.sortedExam = sortExam;
    },
    showWarning() {
      this.leaveWarning = true;
    },
    hideWarning() {
      this.leaveWarning = false;
    },
    examWrapperBackBtn() {
      this.$router.push('/');
      this.$store.commit("accountPopup", true);
      this.$store.commit('accountPage', "exam");
    },
    examNextBtn() {
      if(this.examPg <= 19) {
        this.$store.commit('changeExplainPopup', true);
      }
    },
    examBackBtn() {
      if(this.examPg > 0) {
        this.$store.commit('changeExamPg', this.examPg-1);
        this.$refs.examScrollWrapper.scrollTop = 0;
      }
    },
    nextQuestion() {
      if(this.examPg < 19) {
        this.$store.commit('changeExamPg', this.examPg+1);
        this.$store.commit('examAnswered', false);
        this.$store.commit('changeExplainPopup', false);
      }
      else if(this.examPg === 19) {
        // this.$router.push('/');
        this.finishPopup = true;
      }
    },
    examProgressCal() {
      let percentageCal = (this.examPg/19)*100;
      this.examProgress = percentageCal.toFixed(0)+"%";
    },
    languageChange() {
      if (this.language === "en") {
        this.$store.commit("changeLanguage", "zh");
      }
      else {
        this.$store.commit("changeLanguage", "en");
      }
    },
    examResult(status) {
      this.examPassed = status ? true : false;
    }
  },
  watch: {
    examPg() {
      this.examProgressCal();
    }
  },
  mounted() {
    this.examProgressCal();
    this.$store.commit('changeExamPg', 0);
    this.getExamData();
  },
  beforeDestroy() {
    let userData = db.collection("user_data").doc(this.userEmail)
    return userData
    .update({
      "examTempScore": 0
    })
    .then(() => {
      console.log("Exam score resetted");
    })
    .catch((err) => {
      console.log("Update exam result error: " + err);
    })
  }
}
</script>

<style lang="scss" scoped>
/* p {
  color: black;
} */
::-webkit-scrollbar {
    display: none;
}
.examWrapper {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 10% 90%;
  overflow: hidden;
  background-color: #202125;

  @media only screen and (min-width: 480px) {
    grid-template-columns: 6% 88% 6%;
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: 8% 84% 8%;
  }
}
.examBtnStatus {
  grid-column: 1/4;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 15% 9% 54% 7% 15%;
  grid-template-rows: 100%;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 8% 27% 32% 25% 8%;
  }
}
.examWrapperBackBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  height: 1.2em;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    height: 1.5em;
  }
}
.examProgressWrapper {
  grid-column: 3/4;
  grid-row: 1/2;
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
  display: grid;
  grid-template-columns: 77% 23%;
  grid-template-rows: 100%;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 80% 20%;
  }

  .examProgressBarWrapper {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 20%;
    width: 100%;
    /* justify-self: center; */
    align-self: center;
    border-radius: 0.8em;
    border: 0.15em solid white;

    .examProgressBar {
      background-color: white;
      display: block;
      /* width: 50%; */
      height: 100%;
      border-radius: 0.5em;
      z-index: 0;
      transition: 0.3s ease-in-out;
    }
  }

  .examProgress {
    grid-column: 2/3;
    grid-row: 1/2;
    color: white;
    justify-self: center;
    align-self: center;
    font-size: 12pt;
  }
}
.langBtn {
  grid-column: 5/6;
  grid-row: 1/2;
  height: 1.5em;
  width: 1.5em;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  border-style: solid;
  border-color: white;
  border-width: 2pt;
  border-radius: 0.4em;
  display: grid;

  @media only screen and (min-width: 768px) {
    height: 2.1em;
    width: 2.1em;
  }

  p {
    color: white;
    font-size: 10pt;
    font-weight: 500;
    text-align: center;
    justify-self: center;
    align-self: center;
    margin: 0;

    @media only screen and (min-width: 768px) {
      font-size: 14pt;
    }
  }
}
 
.examNextBtn {
  grid-column: 3/4;
  grid-row: 2/3;
  height: 48px;
  justify-self: center;
  align-self: center;
  cursor: pointer;
}
.examBackBtn {
  grid-column: 1/2;
  grid-row: 2/3;
  height: 48px;
  justify-self: center;
  align-self: center;
  cursor: pointer;
}
.examScrollWrapper {
  grid-column: 2/3;
  grid-row: 2/3;
  height: 100%;
  width: 100%;
  /* border-radius: 1.5em; */
  overflow: auto;
}
.examContentWrapper {
  grid-column: 2/3;
  grid-row: 2/3;
  z-index: 20;
  justify-self: center;
  align-self: center;
  max-height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 2em;
}
.examContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 8% 84% 8%;
  /* grid-template-rows: 15% minmax(min-content, max-content) 10%; */
  background-color: white;
  border-radius: 1.5em;
  justify-self: center;
  align-self: center;
}
.examButtonWrapper {
  grid-column: 2/3;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  padding: 4% 0 5% 0;
}
.examSignUpBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  height: 46px;
  width: 232px;
  background-color: #F66F51;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.examSignUpBtn:hover {
  background-color: black;
}
.examCancelBtn {
  grid-column: 2/3;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  height: 46px;
  width: 232px;
  background-color: #F66F51;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.examCancelBtn:hover {
  background-color: black;
}
.examBtnText {
  color: white;
  align-self: center;
  font-size: 12pt;
  margin: 0;
  align-self: center;
  justify-self: center;
}
.leaveWarningWrapper {
  grid-column: 1/4;
  grid-row: 1/3;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
.leaveWarningBg {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 100vh;
  width: 100vw;
  background-color: #BFBFBF;
  opacity: 0.7;
  z-index: 20;
}
.leaveWarning {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 25em;
  width: 90%;
  background-color: white;
  justify-self: center;
  align-self: center;
  border-radius: 2em;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 45% 40%;
  z-index: 20;

  @media only screen and (min-width: 768px) {
    height: 518px;
    width: 673px;
  }
}
.leaveWarningText {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 75%;
  font-size: 12pt;
  font-weight: bold;
  justify-self: center;
  align-self: center;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 16pt;
    width: 65%;
  }

  span {
    color: #3BC146;
    font-weight: bold;
  }
}
.leaveWarningBtnWrapper {
  grid-column: 1/2;
  grid-row: 3/4;
  display: grid;
  // grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  width: 80%;
  justify-self: center;

  @media only screen and (min-width: 768px) {
    width: 70%;
  }
}
.leaveBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100px;
  height: 36px;
  justify-self: center;
  align-self: center;
  background-color: #F43434;
  border-style: solid;
  border-width: 2pt;
  border-radius: 3.5em;
  border-color: #F43434;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  @media only screen and (min-width: 768px) {
    width: 130px;
    height: 46px;
  }

  &:hover {
    background-color: black;
    border-color: black;
  }

  p {
    justify-self: center;
    align-self: center;
    color: white;
    font-size: 10pt;
    margin: 0;

    @media only screen and (min-width: 768px) {
      font-size: 12pt;
    }
  }
}

.cancelBtn {
  grid-column: 2/3;
  grid-row: 1/2;
  width: 100px;
  height: 36px;
  justify-self: center;
  align-self: center;
  background-color: white;
  border-style: solid;
  border-width: 2pt;
  border-radius: 3.5em;
  display: grid;
  cursor: pointer;
  color: black;
  transition: 0.3s ease-in-out;

  @media only screen and (min-width: 768px) {
    width: 130px;
    height: 46px;
  }

  &::hover {
    background-color: black;
    border-color: black;
    color: white;
  }

  p {
    justify-self: center;
    align-self: center;
    font-size: 10pt;
    margin: 0;

    @media only screen and (min-width: 768px) {
      font-size: 12pt;
    }
  }
}

.explainPopupWrapper {
  grid-column: 1/4;
  grid-row: 1/3;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  z-index: 20;
}
.explainPopupBg {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 100vh;
  width: 100vw;
  background-color: #BFBFBF;
  opacity: 0.7;
  z-index: 20;
}
.explainPopup {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 518px;
  width: 673px;
  background-color: white;
  justify-self: center;
  align-self: center;
  border-radius: 2em;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 55% 30%;
  z-index: 20;
}
.explainPopupText {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 65%;
  font-size: 16pt;
  font-weight: bold;
  justify-self: center;
  align-self: center;
  margin: 0;
}
.explainPopupBtn {
  grid-column: 1/2;
  grid-row: 3/4;
  width: 130px;
  height: 46px;
  justify-self: center;
  align-self: center;
  background-color: #3BC146;
  border-style: solid;
  border-width: 2pt;
  border-radius: 3.5em;
  border-color: #3BC146;
  display: grid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.explainPopupBtn:hover {
  background-color: black;
  border-color: black;
  /* color: white; */
}
.hidden {
  display: none;
}
// @media only screen and (max-height: 960px)  and (orientation: landscape) {
//   .examWrapperBackBtn {
//     height: 20px;
//   }
//   .examProgressBarWrapper {
//     height: 22%;
//     width: 100%;
//     align-self: center;
//     border-style: solid;
//     border-radius: 0.8em;
//   }
//   .examProgressBar {
//     border-radius: 0.5em;
//     z-index: 0;
//     transition: 0.3s ease-in-out;
//   }
//   .langBtn {
//     height: 1.8em;
//     width: 1.8em;
//     border-radius: 0.3em;
//   }
//   .langBtn p {
//     font-size: 12pt;
//   }
// }
// @media only screen and (max-height: 900px)  and (orientation: landscape) {
//   .examBtnText {
//     font-size: 10pt;
//   }
// }
// @media only screen and (max-height: 768px)  and (orientation: landscape) {
//   .examWrapper {
//     grid-template-columns: 8% 84% 8%;
//     grid-template-rows: 12% 88%;
//   }
// }
</style>
